import { Enumeration } from './Enumeration';

class LegalUnitEnum extends Enumeration {
  Norway = 'Norway';
  Sweden = 'Sweden';
  Finland = 'Finland';
  Denmark = 'Denmark';

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const LegalUnit = new LegalUnitEnum();
