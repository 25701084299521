export const COUNTRY_CODE_DENMARK = 'DK';
export const COUNTRY_CODE_FINLAND = 'FI';
export const COUNTRY_CODE_NORWAY = 'NO';
export const COUNTRY_CODE_SWEDEN = 'SE';
export const COUNTRY_CODE_GREENLAND = 'GL';

export const addressFormats = {
  Unstructured: 'Unstructured',
  Street: 'Street',
  POBox: 'POBox',
  Freetext: 'Freetext',
  Foreign: 'Foreign',
};

// not in use currently
export const addressTypes = {
  Primary: 'Primary',
  Visiting: 'Visiting',
  Invoice: 'Invoice',
  Other: 'Other',
};

export const addressActions = {
  View: 0,
  Edit: 1,
  Create: 2,
  Warning: 3,
  Error: 4,
  Loading: 5,
};

export const careOfFields = [
  {
    id: 1,
    value: 'None',
  },
  {
    id: 2,
    value: 'C/O',
  },
  {
    id: 3,
    value: 'V',
  },
  {
    id: 4,
    value: 'Attn',
  },
];
