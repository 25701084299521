import { SalesCodeState } from '../Enums/SalesCodeState';
import { BusinessArea } from '../Enums/BusinessArea';

export function isSalesCodeActive(salesCode) {
  if (!salesCode || !salesCode.state) return false;

  const state = salesCode.state;
  return (
    state === SalesCodeState.Active || state === SalesCodeState.NotActiveYet
  );
}

export function isSalesCodeHistorical(salesCode) {
  if (!salesCode || !salesCode.state) return false;

  const state = salesCode.state;
  return (
    state === SalesCodeState.ExpiringToday || state === SalesCodeState.Expired
  );
}

export function getBusinessAreaByChannel(channel) {
  switch (channel?.generalLedgerCostCenterCode?.charAt(0)) {
    case 'K':
      return BusinessArea.Private;
    case 'C':
      return BusinessArea.Commercial;
    case 'B':
      return BusinessArea.Industrial;
    default:
      return null;
  }
}
