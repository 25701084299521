import {
  Modal,
  SecondaryButton,
  PrimaryButton,
  Grid,
} from '@customer/react-relax';
import React, { Component } from 'react';
import './SalesCodeDetails.css';
import InputElement from '../../Elements/InputElement';
import { ChannelRepositoryInstance } from '../../Storage/ChannelRepository';
import { PresentationMode } from '../../Enums/PresentationMode';
import classnames from 'classnames';
import {
  ConvertErrorToNewLines,
  legalUnitSwitch,
} from '../../Helpers/Converter';
import RowCol from '../../Elements/RowCol';
import SearchDropdownField from '../../Elements/SearchDropdownField';
import { ServiceErrorHandlingResult } from '../../Services/ServiceErrorHandler';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';
import { GlobalContext } from '../../GlobalContext';
import { Notification } from '../../Elements/Notification';
import CheckboxElement from '../../Elements/CheckboxElement';
import { NotificationType } from '../../Enums/NotificationType';
import { getBusinessAreaByChannel } from '../../Helpers/SalesCodeHelper';
import Email from '../../Elements/Email';
import { validateEmail } from '../../Helpers/Validator';
import { BusinessArea } from '../../Enums/BusinessArea';

class SalesCodeDetails extends Component {
  static contextType = GlobalContext;

  static defaultData = {
    selectedChannelId: null,
    selectedRegionId: null,
    selectedCostCenterId: null,
    email: '',
    isValidEmail: true,
    phone: '',
    canBePortfolioResponsible: false,
    externalSalesCode: '',
    additionalName: '',
    groupEmail: '',
    isGroupEmailValid: true,
    isDigitalCustomer: null,
    availableForXmlExport: null,
  };

  static defaultProps = {
    saveCallback: () => {},
    cancelCallback: () => {},
    setIsShown: () => {},
    isShown: false,
  };

  state = {
    mode: PresentationMode.View,
    legalUnit: null,
    legalUnitMatchesPreferences: true,
    salesCodeItem: null,
    channels: null,
    regions: null,
    costCenters: null,
    data: {
      selectedChannelId: null,
      selectedRegionId: null,
      selectedCostCenterId: null,
      canBePortfolioResponsible: null,
      externalSalesCode: null,
      additionalName: null,
      groupEmail: null,
      isGroupEmailValid: null,
      isDigitalCustomer: null,
      availableForXmlExport: null,
      email: null,
      isValidEmail: null,
      phone: null,
    },
    showError: false,
    errorMessage: '',
  };

  componentDidMount() {
    this.setState({
      salesCodeItem: this.props.salesCodeItem,
    });

    ChannelRepositoryInstance.onInitialized(() => {
      var legalUnit = legalUnitSwitch(this.context?.legalUnit);
      var businessArea = this.context?.businessArea;
      this.setState({
        channels: this.getChannels(legalUnit, businessArea),
        regions: this.getRegions(
          this.state?.data?.selectedChannelId,
          legalUnit,
          businessArea
        ),
        costCenters: this.getCostCenters(
          this.state?.data?.selectedRegionId,
          this.state?.data?.selectedChannelId,
          legalUnit,
          businessArea
        ),
      });
    });
  }

  componentDidUpdate(prevProps) {
    // if component is invisible, do nothing
    if (!this.props.isShown) {
      return;
    }

    let newState = {};

    const loadChannelsTree = () => {
      if (!newState.regions) {
        newState.regions = this.getRegions(
          newState.data?.selectedChannelId,
          newState.legalUnit,
          newState.businessArea
        );
      }

      if (!newState.costCenters) {
        newState.costCenters = this.getCostCenters(
          newState.data?.selectedRegionId,
          newState.data?.selectedChannelId,
          newState.legalUnit,
          newState.businessArea
        );
      }

      if (!newState.channels) {
        newState.channels = this.getChannels(
          newState.legalUnit,
          newState.businessArea
        );
      }
    };

    // is salesCodeItems was changed (if user choose another sales code or adding new one) or data was purged last time
    const componentJustBecameShown = !prevProps?.isShown && this.props.isShown;
    let hasNewData = false;
    if (
      prevProps.salesCodeItem?.salesCode !==
        this.props.salesCodeItem?.salesCode ||
      (this.state.dataPurged && componentJustBecameShown)
    ) {
      newState = {
        ...newState,

        channels: null,
        salesCodeItem: this.props.salesCodeItem,
        data: { ...this.defaultData },
        dataPurged: false,
        isChanged: false,
        isValid: false,
      };

      // if we are in edit or view mode
      if (
        (this.props.mode === PresentationMode.Edit ||
          this.props.mode === PresentationMode.View) &&
        this.props.salesCodeItem != null
      ) {
        newState.data = {
          selectedChannelId: this.props.salesCodeItem?.channel?.id,
          selectedRegionId: this.props.salesCodeItem?.region?.id,
          selectedCostCenterId: this.props.salesCodeItem?.costCenter?.id,
          canBePortfolioResponsible: this.props.salesCodeItem
            ?.canBePortfolioResponsible,
          externalSalesCode: this.props.salesCodeItem?.externalSalesCode,
          additionalName: this.props.salesCodeItem?.additionalName,
          groupEmail: this.props.salesCodeItem?.extension?.groupEmail,
          isDigitalCustomer: this.props.salesCodeItem?.extension
            ?.isDigitalCustomer,
          availableForXmlExport: this.props.salesCodeItem?.extension
            ?.availableForXmlExport,
          email: this.props.salesCodeItem?.email,
          phone: this.props.salesCodeItem?.phone,
        };

        const legalUnitName = this.props.salesCodeItem?.region?.legalUnitName;
        newState.legalUnit = legalUnitName ?? newState.legalUnit;
        newState.legalUnitMatchesPreferences =
          !legalUnitName ||
          legalUnitName === legalUnitSwitch(this.context?.legalUnit);

        newState.businessArea =
          getBusinessAreaByChannel(this.props.salesCodeItem?.channel) ??
          this.context?.businessArea;
        newState.isValid = this.isValid(newState.data);
        hasNewData = true;
      }
    }

    if (hasNewData) {
      loadChannelsTree();
    }
    // is component is shown for first time, load channels
    else if (this.state.channels == null) {
      // set legalUnit, if we didn't set it yet
      if (!newState.legalUnit) {
        newState.legalUnit = legalUnitSwitch(this.context?.legalUnit);
        newState.legalUnitMatchesPreferences = true;
      }

      if (!newState.businessArea) {
        newState.businessArea = this.context?.businessArea;
      }

      loadChannelsTree();
    }

    if (Object.entries(newState).length !== 0) {
      // check if we need to update state at all
      this.setState(newState);
    }
  }

  render() {
    let buttons = [
      <SecondaryButton key="cancel" onClick={this.onCancel.bind(this)}>
        {this.props.mode === PresentationMode.View
          ? formatMessage(Messages.close)
          : formatMessage(Messages.cancel)}
      </SecondaryButton>,
    ];
    if (
      this.props.mode === PresentationMode.Add ||
      this.props.mode === PresentationMode.Edit
    ) {
      buttons.push(
        <PrimaryButton
          key="save"
          onClick={this.onSave.bind(this)}
          disabled={!this.state.isValid || !this.state.isChanged}>
          {`${
            this.props.mode === PresentationMode.Edit
              ? formatMessage(Messages.save)
              : formatMessage(Messages.create)
          } ${formatMessage(Messages.salesCode).toLowerCase()}`}
        </PrimaryButton>
      );
    }

    return (
      <Modal
        isOpen={this.props.isShown}
        onRequestClose={this.onCancel.bind(this)}
        title={this.getWindowTitle()}
        boxClasses="sales-code-details"
        closeButtonText=""
        buttons={buttons}>
        <Grid>
          {!this.state.legalUnitMatchesPreferences && (
            <RowCol>
              <Notification
                type={NotificationType.Warning}
                hideCloseButton={true}
                autoClose={false}>
                {formatMessage(Messages.SalesAgentLUDifferentFromSelectedLU, {
                  salesAgentLU: this.state.legalUnit,
                  selectedLU: legalUnitSwitch(this.context?.legalUnit),
                })}
              </Notification>
            </RowCol>
          )}
          <RowCol>
            <SearchDropdownField
              label={formatMessage(Messages.channel)}
              placeholder={
                formatMessage(Messages.choose) +
                ' ' +
                formatMessage(Messages.channel).toLowerCase()
              }
              searchSubjectName={formatMessage(Messages.channel).toLowerCase()}
              textOverlayFunction={item => item.generalLedgerCostCenterCode}
              labelFunction={item => item.name}
              itemToColumnsConverter={item => {
                return [`${item.name} (${item.generalLedgerCostCenterCode})`];
              }}
              disabled={this.props.mode === PresentationMode.View}
              items={this.state.channels}
              selectedItem={this.state.channels?.find(
                x => x.id === this.state.data?.selectedChannelId
              )}
              onItemSelect={val => this.onChannelChange(val?.id ?? null)}
            />
          </RowCol>
          <RowCol>
            <SearchDropdownField
              label={formatMessage(Messages.region)}
              placeholder={
                formatMessage(Messages.choose) +
                ' ' +
                formatMessage(Messages.region).toLowerCase()
              }
              searchSubjectName={formatMessage(Messages.region).toLowerCase()}
              textOverlayFunction={item => item.code}
              labelFunction={item => item.name}
              itemToColumnsConverter={item => {
                const channel = this.getChannel(item.channelId);
                return [
                  `${item.name} (${item.code})`,
                  `${channel.name} (${channel.generalLedgerCostCenterCode})`,
                ];
              }}
              disabled={this.props.mode === PresentationMode.View}
              items={this.state.regions}
              selectedItem={this.state.regions?.find(
                x => x.id === this.state.data?.selectedRegionId
              )}
              onItemSelect={val => this.onRegionChange(val?.id ?? null)}
            />
          </RowCol>
          <RowCol>
            <SearchDropdownField
              label={formatMessage(Messages.costCenter)}
              placeholder={
                formatMessage(Messages.choose) +
                ' ' +
                formatMessage(Messages.costCenter)
              }
              searchSubjectName={formatMessage(
                Messages.costCenter
              ).toLowerCase()}
              textOverlayFunction={item => item.code}
              labelFunction={item => item.name}
              itemToColumnsConverter={item => {
                const region = this.getRegion(item.regionId, true);
                const channel = this.getChannel(item.channelId);
                return [
                  `${item?.name} (${item?.code})`,
                  `${region?.name} (${region?.code})`,
                  `${channel?.name} (${channel?.generalLedgerCostCenterCode})`,
                ];
              }}
              disabled={this.props.mode === PresentationMode.View}
              items={this.state.costCenters}
              selectedItem={this.state.costCenters?.find(
                x =>
                  x.id === this.state.data?.selectedCostCenterId &&
                  this.state.regions.some(region => (region.id = x.regionId))
              )}
              onItemSelect={val => this.onCostCenterChange(val?.id ?? null)}
            />
          </RowCol>
          <Email
            placeholder={formatMessage(Messages.optional)}
            className="email-input"
            value={this.state.data?.email}
            onChangeValue={val => {
              let newState = this.setStateData(this.state, {
                email: val,
                isValidEmail: validateEmail(val),
              });
              this.setState(newState);
            }}>
            {this.state.data?.email}
          </Email>
          <InputElement
            title={formatMessage(Messages.phone)}
            placeholder={formatMessage(Messages.optional)}
            disabled={this.props.mode === PresentationMode.View}
            value={this.state.data?.phone}
            onChangeValue={val => {
              let newState = this.setStateData(this.state, {
                phone: val,
              });
              this.setState(newState);
            }}></InputElement>
          <InputElement
            title={formatMessage(Messages.externalSalesCode)}
            placeholder={formatMessage(Messages.optional)}
            disabled={this.props.mode === PresentationMode.View}
            value={this.state.data?.externalSalesCode}
            onChangeValue={val => {
              let newState = this.setStateData(this.state, {
                externalSalesCode: val,
              });
              this.setState(newState);
            }}></InputElement>
          {(this.state.businessArea ?? this.context?.businessArea) ===
            BusinessArea.Commercial && (
            <InputElement
              title={formatMessage(Messages.additionalName)}
              placeholder={formatMessage(Messages.optional)}
              disabled={this.props.mode === PresentationMode.View}
              value={this.state.data?.additionalName}
              onChangeValue={val => {
                let newState = this.setStateData(this.state, {
                  additionalName: val,
                });
                this.setState(newState);
              }}></InputElement>
          )}
          {this.props.isBrokerFunctionsAvailable && (
            <>
              <Email
                title={formatMessage(Messages.groupEmail)}
                placeholder={formatMessage(Messages.optional)}
                value={this.state.data?.groupEmail}
                className="email-input"
                onChangeValue={val => {
                  let newState = this.setStateData(this.state, {
                    groupEmail: val,
                    isValidGroupEmail: validateEmail(val),
                  });
                  this.setState(newState);
                }}>
                {this.state.data?.groupEmail}
              </Email>
              <CheckboxElement
                checked={this.state.data?.isDigitalCustomer}
                onChangeValue={val => {
                  let newState = this.setStateData(this.state, {
                    isDigitalCustomer: val,
                  });
                  this.setState(newState);
                }}>
                {formatMessage(Messages.digital)}
              </CheckboxElement>
              <CheckboxElement
                checked={this.state.data?.availableForXmlExport}
                onChangeValue={val => {
                  let newState = this.setStateData(this.state, {
                    availableForXmlExport: val,
                  });
                  this.setState(newState);
                }}>
                {formatMessage(Messages.xmlExport)}
              </CheckboxElement>
            </>
          )}
          <CheckboxElement
            checked={this.state.data?.canBePortfolioResponsible}
            onChangeValue={val => {
              let newState = this.setStateData(this.state, {
                canBePortfolioResponsible: val,
              });
              this.setState(newState);
            }}>
            {formatMessage(Messages.canBePortfolioResponsible)}
          </CheckboxElement>
          <div
            className={classnames(
              'errormessage ' +
                (this.state.showError
                  ? 'errormessage-visible'
                  : 'errormessage-hidden')
            )}>
            <span>{this.state.errorMessage}</span>
          </div>
        </Grid>
      </Modal>
    );
  }

  onChannelChange(newChannelId) {
    const channelChanged = newChannelId !== this.state.data.selectedChannelId;
    if (!channelChanged) {
      return;
    }

    // set selected channel
    let newState = this.setStateData(this.state, {
      selectedChannelId: newChannelId,
    });

    // clear selected region and cost center
    if (newChannelId !== this.getRegion()?.channelId) {
      newState = this.setStateData(newState, { selectedRegionId: null });
    }
    if (newChannelId !== this.getCostCenter()?.channelId) {
      newState = this.setStateData(newState, { selectedCostCenterId: null });
    }

    // update regions and cost centers list
    newState.regions = this.getRegions(
      newChannelId,
      this.state.legalUnit,
      this.state.businessArea
    );
    newState.costCenters = this.getCostCenters(
      null,
      newChannelId,
      this.state.legalUnit,
      this.state.businessArea
    );

    this.setState(newState);
  }

  onRegionChange(newRegionId) {
    const regionChanged = newRegionId !== this.state.data.selectedRegionId;
    if (!regionChanged) {
      return;
    }

    // set selected region
    let newState = this.setStateData(this.state, {
      selectedRegionId: newRegionId,
    });

    // clear selected cost center
    if (newRegionId !== this.getCostCenter()?.regionId) {
      newState = this.setStateData(newState, { selectedCostCenterId: null });
    }

    // set selected channel
    const region = this.getRegion(newRegionId);
    if (region) {
      newState = this.setStateData(newState, {
        selectedChannelId: region.channelId,
      });
    }

    // update regions and cost centers list
    newState.regions = this.getRegions(region.channelId);
    newState.costCenters = this.getCostCenters(newRegionId, region.channelId);

    this.setState(newState);
  }

  onCostCenterChange(newCostCenterId) {
    const costCenterChanged =
      newCostCenterId !== this.state.data.selectedCostCenterId;
    if (!costCenterChanged) {
      return;
    }

    // set selected cost center
    let newState = this.setStateData(this.state, {
      selectedCostCenterId: newCostCenterId,
    });

    // set selected channel and region
    const costCenter = this.getCostCenter(newCostCenterId);
    if (costCenter) {
      newState = this.setStateData(newState, {
        selectedRegionId: costCenter.regionId,
        selectedChannelId: costCenter.channelId,
      });
    }

    // update regions and cost centers list
    newState.regions = this.getRegions(costCenter.channelId);
    newState.costCenters = this.getCostCenters(
      costCenter.regionId,
      costCenter.channelId
    );

    this.setState(newState);
  }

  getChannels(legalUnit, businessArea) {
    const channels = ChannelRepositoryInstance.getNotEmptyChannels(
      legalUnit ?? this.state.legalUnit,
      businessArea
    );
    return channels;
  }

  getChannel(id) {
    id = id ?? this.state.data.selectedChannelId;
    if (!id) {
      return null;
    }
    return ChannelRepositoryInstance.getChannel(id);
  }

  getRegions(channel, legalUnit, businessArea) {
    const regions = ChannelRepositoryInstance.getNotEmptyRegions(
      channel,
      legalUnit ?? this.state.legalUnit,
      businessArea ?? this.state.businessArea
    );
    return regions;
  }

  getRegion(id) {
    id = id ?? this.state.data.selectedRegionId;
    if (!id) {
      return null;
    }
    return ChannelRepositoryInstance.getRegion(id);
  }

  getCostCenters(region, channel, legalUnit, businessArea) {
    const costCenters = ChannelRepositoryInstance.getCostCenters(
      region,
      channel,
      legalUnit ?? this.state.legalUnit,
      businessArea ?? this.state.businessArea
    );
    return costCenters;
  }

  getCostCenter(id) {
    id = id ?? this.state.data.selectedCostCenterId;
    if (!id) {
      return null;
    }
    return ChannelRepositoryInstance.getCostCenter(id);
  }

  setStateData(state, val) {
    let newData = { ...state.data, ...val };
    var dataIsValid = this.isValid(newData);
    let newState = { data: newData, isValid: dataIsValid, isChanged: true };
    return newState;
  }

  onSave() {
    this.props.saveCallback(
      this.state.data,
      () => {
        //If Succeeded
        this.setState(
          { data: this.defaultData, dataPurged: true, showError: false },
          () => {
            this.props.setIsShown(false);
          }
        );
      },
      (message, error, context) => {
        const errorMessage = ConvertErrorToNewLines(context?.errorTexts);
        this.setState({ showError: true, errorMessage });
        return new ServiceErrorHandlingResult(true, true);
      }
    );
  }

  onCancel() {
    this.props.cancelCallback();
    this.setState(
      { data: this.defaultData, dataPurged: true, showError: false },
      () => {
        this.props.setIsShown(false);
      }
    );
  }

  isValid(data) {
    if (
      !data.selectedChannelId ||
      !data.selectedRegionId ||
      !data.selectedCostCenterId ||
      data.isValidEmail === false ||
      data.isValidGroupEmail === false
    ) {
      return false;
    }
    return true;
  }

  getWindowTitle() {
    switch (this.props.mode) {
      case PresentationMode.Add:
        return formatMessage(Messages.presentationModeAddHeader, {
          salesId: this.props.item?.salesId,
        });
      case PresentationMode.Edit:
        return formatMessage(Messages.presentationModeEditHeader, {
          salesCode: this.state.salesCodeItem?.salesCode,
        });
      case PresentationMode.View:
        return formatMessage(Messages.presentationModeViewHeader, {
          salesCode: this.state.salesCodeItem?.salesCode,
        });
      default:
        return null;
    }
  }
}

export default SalesCodeDetails;
