export function logRequestInterceptor(request) {
  console.log('axios request', request);
  return request;
}
export function logRequestErrorInterceptor(requestError) {
  console.error('axios response error', requestError);
  return Promise.reject(requestError);
}
export function logResponseInterceptor(response) {
  console.log('axios response', response);
  return response;
}
export function logResponseErrorInterceptor(responseError) {
  console.error('axios response error', responseError);
  return Promise.reject(responseError);
}
