import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { generateElementId } from '../Helpers/ElementIdGenerator';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './SearchDropdownField.css';
import Messages from '../Localization/Messages';
import { formatMessage } from '../Localization/formatMessage';

const MAX_RESULTS = 100;

class SearchDropdownField extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    searchSubjectName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,

    labelFunction: PropTypes.func,
    filterFunction: PropTypes.func,
    textOverlayFunction: PropTypes.func,

    // items should be converted to array, or 'columns'
    // It is needed to show columns in search result
    itemToColumnsConverter: PropTypes.func,
    enableItemToColumnsConverterCaching: PropTypes.bool,
    items: PropTypes.array,
    selectedItem: PropTypes.any,
    onItemSelect: PropTypes.func,
    onTextValueInput: PropTypes.func,
    onTextValueImmediateInput: PropTypes.func,
    onFilter: PropTypes.func,
    doNotClearOnInput: PropTypes.bool,
    isLoading: PropTypes.bool,
    showHeader: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    label: null,
    placeholder: null,
    searchSubjectName: 'SEARCH SUBJECT',
    disabled: false,

    labelFunction: function(item) {
      return this.convertItemToColumns(item)[0];
    },
    filterFunction: function(item, text, config) {
      return (this.convertItemToColumns(item)[0] ?? '')
        .toLowerCase()
        .includes(text.toLowerCase());
    },
    textOverlayFunction: item => '',

    itemToColumnsConverter: function(item) {
      if (!item) return [];
      if (item.columns) return [...item.columns];
      return [item];
    },
    enableItemToColumnsConverterCaching: true,
    items: [],
    selectedItem: null,
    onItemSelect: item => {},
    onTextValueInput: text => {},
    onTextValueImmediateInput: text => {},
    onFilter: (filteredResults, state) => {},
    doNotClearOnInput: false,
  };

  typeahedRef = React.createRef();

  state = {
    selectedValue: null,
    justFocused: false,
    inputText: '',
    lastFiltered: [],
  };

  // needed to deselect input after user chose data.
  // !!! Value is set only after first focus on input !!!
  typeaheadInputReference = null;
  // little optimization, so we won't call convertItemToColumns function too much
  itemColumnsDictionary = new WeakMap();
  textModified = false;

  blur() {
    this.typeahedRef.current.blur();
  }

  convertItemToColumns(item) {
    if (this.props.enableItemToColumnsConverterCaching) {
      const cached = this.itemColumnsDictionary.get(item);
      if (cached) return cached;
    }
    const converted = this.props.itemToColumnsConverter(item);
    if (item && this.props.enableItemToColumnsConverterCaching) {
      this.itemColumnsDictionary.set(item, converted);
    }
    return converted;
  }

  componentDidMount() {
    this.setState({ selectedValue: this.props.selectedItem });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedItem !== prevProps.selectedItem ||
      (this.props !== prevProps && this.props.forceUpdateSelectedItem)
    ) {
      this.setState({ selectedValue: this.props.selectedItem });
    }
  }

  convertToColumns() {}

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null || a.length !== b.length) return false;

    for (var i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  render() {
    return (
      <fieldset className={`dropdown-block ${this.props.className}`}>
        {this.props.label && (
          <label className="relax-label-heading">{this.props.label}</label>
        )}
        <div className="relax-select search-dropdown-field">
          <Typeahead
            id={generateElementId()}
            ref={this.typeahedRef}
            maxResults={MAX_RESULTS}
            align="left"
            positionFixed={true}
            highlightOnlyResult={true}
            paginate={false}
            isLoading={this.props.isLoading}
            placeholder={this.props.placeholder}
            options={this.props.items ?? []}
            disabled={this.props.disabled}
            selected={
              this.state.selectedValue ? [this.state.selectedValue] : []
            }
            onChange={item => {
              this.onItemSelect(
                item,
                !this.textModified ||
                  item?.length ||
                  !this.props.doNotClearOnInput
              );
            }}
            filterBy={(item, config) => {
              return this.props.filterFunction.bind(this)(
                item,
                config.text,
                config
              );
            }}
            labelKey={this.props.labelFunction.bind(this)}
            onFocus={e => {
              this.typeaheadInputReference = e.target;
              // we need to proceed only when event is first time focus, sometimes it's just 'click'
              if (e.type !== 'focus') {
                return;
              }
              this.textModified = false;
              this.setState({ justFocused: true }, () => {
                // select all input contents
                this.typeaheadInputReference.select();
              });
            }}
            onInputChange={val => {
              this.textModified = true;
              this.setState({
                justFocused: false,
                inputText: val,
              });
              this.props.onTextValueImmediateInput(val);
            }}
            onBlur={() => {
              this.setState({ justFocused: false });

              if (this.textModified) {
                this.props.onTextValueInput(this.state.inputText);
              }
              this.textModified = false;
            }}
            renderMenu={(results, menuProps) => {
              if (
                !this.arraysEqual(results, this.state.lastFiltered) ||
                menuProps.text !== this.state.inputText
              ) {
                var filtered = [...results];
                this.setState({
                  lastFiltered: filtered,
                  inputText: menuProps.text,
                });
                this.props.onFilter(filtered, menuProps);
              }

              // Note: this component is not rendered inside of Typeahead, but in the end of document
              return (
                <Menu
                  {...menuProps}
                  className="search-dropdown-field-menu relax-search-result-list relax-panel relax-lightGrey">
                  {this.props.showHeader && (
                    <div className="search-dropdown-field-menu-header">
                      {!results || !results.length
                        ? formatMessage(
                            Messages.searchDropDownCantFindMessage,
                            {
                              searchSubjectName: this.props.searchSubjectName,
                              text: menuProps.text,
                            }
                          )
                        : formatMessage(Messages.searchDropDownSearchorChoose, {
                            searchSubjectName: this.props.searchSubjectName,
                          })}
                    </div>
                  )}
                  {/* If user just focused on input, show all available data */}
                  {(this.state.justFocused && this.state.selectedValue
                    ? (this.props.items ?? []).slice(0, MAX_RESULTS)
                    : results
                  ).map(
                    (result, index) =>
                      (result.customDropdownItemRender &&
                        result.customDropdownItemRender(result)) || (
                        <MenuItem option={result} position={index} key={index}>
                          <div className="search-result-item-row">
                            {this.convertItemToColumns(result).map(
                              (resCol, index2) => (
                                <div
                                  key={index2}
                                  className="search-result-item-col">
                                  <div className="search-result-item">
                                    {resCol}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </MenuItem>
                      )
                  )}
                </Menu>
              );
            }}></Typeahead>
          {this.props.textOverlayFunction && this.state.selectedValue ? (
            <div className="dropdown-value-text">
              {this.props.textOverlayFunction(this.state.selectedValue)}
            </div>
          ) : (
            ''
          )}
        </div>
      </fieldset>
    );
  }

  onItemSelect(items, raiseEvent) {
    const item = items[0];
    if (item !== this.state.selectedValue) {
      if (this.props.onItemSelect && raiseEvent) {
        this.props.onItemSelect(item);
      }
      this.setState({ selectedValue: item });
    }
    // blur input after selecting item
    if (
      item &&
      this.typeaheadInputReference &&
      this.typeaheadInputReference.blur &&
      typeof this.typeaheadInputReference.blur === 'function'
    ) {
      this.typeaheadInputReference.blur();
    }
  }
}

export default SearchDropdownField;
