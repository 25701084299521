import SessionStorage from './SessionStorage';
import { getBusinessAreaByChannel } from '../Helpers/SalesCodeHelper';
import { isSameBusinessArea } from '../Enums/BusinessArea';

const CHANNELS_KEY = 'channels';
const REGIONS_KEY = 'regions';
const COSTCENTERS_KEY = 'costCenters';

class ChannelRepository {
  constructor(storage) {
    this.storage = storage;
  }

  initialized = false;
  initializedCallbacks = [];

  initialize(channels) {
    let regions = [];
    let costCenters = [];

    channels.forEach(channel => {
      const channelBusinessArea = this.getBusinessArea(channel);
      const mappedRegions = channel.regions.map(x => {
        let res = {
          ...x,
          id: x.id,
          channelId: channel.id,
          businessArea: channelBusinessArea,
        };
        delete res.costCenters;
        return res;
      });
      regions = [...regions, ...mappedRegions];

      channel.regions.forEach(region => {
        const mappedCostCenters = region.costCenters.map(x => {
          let res = {
            ...x,
            id: x.id,
            channelId: channel.id,
            regionId: region.id,
            regionLegalUnit: region.legalUnitName,
            businessArea: channelBusinessArea,
          };
          return res;
        });
        costCenters = [...costCenters, ...mappedCostCenters];
      });
    });

    let mappedChannels = channels.map(x => {
      let res = { ...x, id: x.id, businessArea: this.getBusinessArea(x) };
      delete res.regions;
      return res;
    });

    this.storage.setItem(CHANNELS_KEY, mappedChannels);
    this.storage.setItem(REGIONS_KEY, regions);
    this.storage.setItem(COSTCENTERS_KEY, costCenters);

    this.initialized = true;
    this.initializedCallbacks.forEach(x => x());
  }

  getChannels() {
    return this.storage.getItem(CHANNELS_KEY) ?? [];
  }

  getRegions(channelId, legalUnit, businessArea) {
    const allRegions = this.storage.getItem(REGIONS_KEY) ?? [];
    if (!channelId && !legalUnit) {
      return allRegions;
    }
    const channelIdString = channelId?.toString();

    return allRegions.filter(
      x =>
        (!channelId || x.channelId.toString() === channelIdString) &&
        (!legalUnit || x.legalUnitName === legalUnit) &&
        (!businessArea || isSameBusinessArea(x.businessArea, businessArea))
    );
  }

  getCostCenters(regionId, channelId, legalUnit, businessArea) {
    // TODO refactor
    let allCostCenters = this.storage.getItem(COSTCENTERS_KEY) ?? [];
    if (!regionId) {
      if (!channelId) {
        if (!legalUnit) {
          if (!businessArea) return allCostCenters;
          return allCostCenters.filter(
            x =>
              !businessArea || isSameBusinessArea(x.businessArea, businessArea)
          );
        }
        return allCostCenters.filter(
          x =>
            (!legalUnit || x.regionLegalUnit === legalUnit) &&
            (!businessArea || isSameBusinessArea(x.businessArea, businessArea))
        );
      }
      const channelIdString = channelId?.toString();
      return allCostCenters.filter(
        x =>
          x.channelId.toString() === channelIdString &&
          (!legalUnit || x.regionLegalUnit === legalUnit) &&
          (!businessArea || isSameBusinessArea(x.businessArea, businessArea))
      );
    }
    const regionIdString = regionId.toString();

    return allCostCenters.filter(x => x.regionId.toString() === regionIdString);
  }

  getNotEmptyChannels(legalUnit, businessArea) {
    let channels = this.getChannels();
    return channels.filter(
      x =>
        this.getNotEmptyRegions(x.id, legalUnit).length &&
        isSameBusinessArea(x.businessArea, businessArea)
    );
  }

  getBusinessArea(channel) {
    let businessArea = getBusinessAreaByChannel(channel);
    if (!businessArea) throw new Error("Couldn't detect Business Area");
    return businessArea;
  }

  getNotEmptyRegions(channelId, legalUnit, businessArea) {
    let regions = this.getRegions(channelId, legalUnit, businessArea);
    // if (businessArea) {
    //   const channels = this.getNotEmptyChannels(legalUnit, businessArea);
    //   regions = regions.filter(
    //     region =>
    //       channels.filter(channel => channel.id === region.channelId).length
    //   );
    // }
    return regions.filter(x => this.getCostCenters(x.id).length);
  }

  getChannel(channelId) {
    if (!channelId) {
      return null;
    }
    const channelIdString = channelId.toString();
    return this.getChannels().find(x => x.id.toString() === channelIdString);
  }

  getRegion(regionId, legalUnit) {
    if (!regionId) {
      return null;
    }
    const regionIdString = regionId.toString();
    return this.getRegions().find(
      x =>
        x.id.toString() === regionIdString &&
        (!legalUnit || x.legalUnitName === legalUnit)
    );
  }

  getCostCenter(costCenterId) {
    if (!costCenterId) {
      return null;
    }
    const costCenterIdString = costCenterId.toString();
    return this.getCostCenters().find(
      x => x.id.toString() === costCenterIdString
    );
  }

  onInitialized(callback) {
    if (this.initialized) {
      callback();
    } else {
      this.initializedCallbacks.push(callback);
    }
  }
}

export const ChannelRepositoryInstance = new ChannelRepository(
  new SessionStorage()
);

export default ChannelRepository;
