import './DateRangeFilterComponent.css';

import React, { useEffect, useState } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import * as _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// partially taken from https://github.com/mbrn/material-table/pull/1351

export function isDateInRange(dateFrom, dateTo, dateToBeFiltered) {
  if (!dateToBeFiltered) {
    return !(dateFrom || dateTo);
  }
  const parsedDateToFilter = new Date(dateToBeFiltered).setHours(0, 0, 0, 0);

  if (!(dateFrom || dateTo)) {
    return true;
  } else if (!dateFrom) {
    return parsedDateToFilter <= new Date(dateTo).setHours(0, 0, 0, 0);
  } else if (!dateTo) {
    return parsedDateToFilter >= new Date(dateFrom).setHours(0, 0, 0, 0);
  }
  return (
    parsedDateToFilter >= new Date(dateFrom).setHours(0, 0, 0, 0) &&
    parsedDateToFilter <= new Date(dateTo).setHours(0, 0, 0, 0)
  );
}

export const DateRangeFilterComponent = ({ columnDef, onFilterChanged }) => {
  var [dateFrom, setDateFrom] = useState(null);
  var [dateTo, setDateTo] = useState(null);

  useEffect(() => {
    let value = { ...columnDef.tableData.filterValue };
    value.dateFrom = dateFrom;
    value.dateTo = dateTo;
    onFilterChanged(columnDef.tableData.id, value);
  }, [columnDef, onFilterChanged, dateFrom, dateTo]);

  return (
    <div className="date-range-container">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format="dd.MM.yyyy"
          value={
            _.get(columnDef, ['tableData', 'filterValue', 'dateFrom']) || null
          }
          onChange={setDateFrom}
        />
        <ExpandMore className="date-range-arrow" />
        <DatePicker
          format="dd.MM.yyyy"
          value={
            _.get(columnDef, ['tableData', 'filterValue', 'dateTo']) || null
          }
          onChange={setDateTo}
        />
      </MuiPickersUtilsProvider>
      <IconButton
        className="date-range-clear-button"
        onClick={() => {
          setDateFrom(null);
          setDateTo(null);
        }}>
        <Clear />
      </IconButton>
    </div>
  );
};
