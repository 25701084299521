import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Notification.css';
import { NotificationType } from '../Enums/NotificationType';
import { getLocalizationForErrorMessage } from '../Localization/getLocalizationForErrorMessage';

class Notification extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    timeout: PropTypes.number,
    inline: PropTypes.bool,
    autoClose: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    closedCallback: PropTypes.func,
  };

  static defaultProps = {
    type: NotificationType.General,
    timeout: 5000,
    autoClose: true,
    closedCallback: null,
    inline: true,
    hideCloseButton: false,
  };

  currentTimeout = null;

  state = {
    isShown: false,
  };

  componentDidMount() {
    this.setShown();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.autoClose !== this.props.autoClose ||
      prevProps.timeout !== this.props.timeout
    ) {
      this.restartAutoClose();
    }
  }

  restartAutoClose() {
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
      this.currentTimeout = null;
    }

    if (this.props.autoClose) {
      this.currentTimeout = setTimeout(() => {
        if (this.props.autoClose) {
          this.setClosed();
        }
      }, this.props.timeout);
    }
  }

  setShown() {
    if (this.state.isShown) {
      return;
    }
    this.setState(
      {
        isShown: true,
      },
      this.restartAutoClose
    );
  }

  setClosed() {
    if (!this.state.isShown) {
      return;
    }
    this.setState({ isShown: false }, () => {
      this.props.closedCallback && this.props.closedCallback();
    });
  }

  getNotificationContents() {
    return this.props.children;
  }

  render() {
    return this.state.isShown ? (
      <div
        className={`notification-container ${
          this.props.inline ? '' : 'notification-container-inline'
        }`}>
        <div
          className={`relax-notification relax-notification--${this.props.type}`}>
          <div className="relax-notification-content">
            {this.getNotificationContents()}
          </div>
          {!this.props.hideCloseButton && (
            <button
              className="relax-notification-close"
              onClick={this.setClosed.bind(this)}></button>
          )}
        </div>
      </div>
    ) : (
      ''
    );
  }
}

class ServerErrorNotification extends Notification {
  static propTypes = {
    ...super.propTypes,
    error: PropTypes.any,
  };

  static defaultProps = {
    ...super.defaultProps,
    error: null,
  };

  getNotificationContents() {
    return getLocalizationForErrorMessage(this.props.error);
  }

  render() {
    return this.props.error ? super.render() : '';
  }
}

export { Notification, ServerErrorNotification };
