import React, { memo } from 'react';

const NewLine = ({ list }) => {
  const isNotEmpty =
    list?.length > 0 && list.some(x => x !== undefined && x.trim() !== '');
  return (
    <>
      {isNotEmpty && (
        <>
          {list.join(' ')}
          <br />
        </>
      )}
    </>
  );
};

export default memo(NewLine);
