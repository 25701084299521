import React, { memo } from 'react';
import './AddressFormatSelector.css';
import { addressFormats } from '../../AddressConstants';
import { formatMessage } from '../../../../Localization/formatMessage';
import Messages from '../../../../Localization/Messages';

const AddressFormatSelector = ({ addressFormat, setAddressFormat }) => {
  return (
    <div className="relax-select address-format-selector-container">
      <select
        value={addressFormat}
        onChange={e => {
          setAddressFormat(e.target.value);
        }}>
        <option value={addressFormats.Street}>
          {formatMessage(Messages.addressComponentFormatSelectorStreet)}
        </option>
        <option value={addressFormats.Foreign}>
          {formatMessage(Messages.addressComponentFormatSelectorForeign)}
        </option>
        <option value={addressFormats.POBox}>
          {formatMessage(Messages.addressComponentFormatSelectorPostBox)}
        </option>
        <option value={addressFormats.Unstructured}>
          {formatMessage(Messages.addressComponentFormatSelectorUnstructured)}
        </option>
        <option value={addressFormats.Freetext}>
          {formatMessage(Messages.addressComponentFormatSelectorFreeText)}
        </option>
      </select>
    </div>
  );
};

export default memo(AddressFormatSelector);
