import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class OutsideClickHandler extends Component {
  // props:
  //  handleAllClicks - handle also middle and right click
  //  onAnyClick - event on all clicks
  //  onInsideClick
  //  onOutsideClick

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    var node = ReactDOM.findDOMNode(this);

    // handle left click only
    if (!e.button === 0 && !this.props.handleAllClicks) {
      return;
    }

    if (this.props.onAnyClick) {
      this.props.onAnyClick(e);
    }

    if (node && node.contains(e.target)) {
      if (this.props.onInsideClick) {
        this.props.onInsideClick(e);
      }
      return;
    }

    if (this.props.onOutsideClick) {
      this.props.onOutsideClick(e);
    }
  };

  render() {
    return this.props.children;
  }
}

export { OutsideClickHandler };
