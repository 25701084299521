import React, { Component } from 'react';
import classnames from 'classnames';
import { generateElementId } from '../Helpers/ElementIdGenerator';

class InputElement extends Component {
  state = {
    checked: false,
  };

  componentDidMount() {
    this.setState({
      checked: this.props.checked ?? '',
      checkboxId: generateElementId(),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({
        checked: this.props.checked ?? '',
      });
    }
  }

  render() {
    const { title, name, disabled, inputElementClasses } = this.props;
    return (
      <div className="relax-selection-controls">
        <input
          type="checkbox"
          id={this.state.checkboxId}
          className={classnames(inputElementClasses, 'relax-selection-control')}
          name={name}
          checked={this.state.checked}
          onChange={this.localOnChangeValue.bind(this)}
          disabled={disabled}
        />
        <label htmlFor={this.state.checkboxId}>
          {title ?? this.props.children}
        </label>
      </div>
    );
  }

  localOnChangeValue(event) {
    if (this.props.readonly) return;

    const val = event.target.checked;
    this.setState({
      checked: val,
    });

    if (this.props.onChangeValue) {
      this.props.onChangeValue(val);
    }
  }
}

export default InputElement;
