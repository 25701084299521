import React from 'react';
import { UpdateSourceSystem } from '../Enums/UpdateSourceSystem';
import Messages from '../Localization/Messages';
import { useIntl } from 'react-intl';

const UpdatedByItem = props => {
  const formatMessage = useIntl().formatMessage;

  function convertToLink(email, userIdentifier, isDefault) {
    if (isDefault) {
      return formatMessage(Messages.updatedByItem_SystemUserName);
    }

    if (!email && !userIdentifier) {
      return formatMessage(Messages.updatedByItem_UnknownUserName);
    }

    if (!email) {
      return userIdentifier;
    }

    return (
      <a href={`mailto:${email}`} target="top">
        {email}
      </a>
    );
  }

  function getSystemName(sourceSystem) {
    var keyPostfix = !UpdateSourceSystem.hasValue(sourceSystem)
      ? UpdateSourceSystem.keyOf(UpdateSourceSystem.Unknown)
      : UpdateSourceSystem.keyOf(sourceSystem);

    return formatMessage(Messages['updatedByItem_SystemName_' + keyPostfix]);
  }

  function hasData(updatedBy) {
    if (!updatedBy) return false;

    var hasSourceSystem =
      UpdateSourceSystem.hasValue(updatedBy.sourceSystem) &&
      updatedBy.sourceSystem !== UpdateSourceSystem.Unknown;
    var hasUserData = !!(updatedBy.userIdentifier || updatedBy.email);

    return hasSourceSystem || hasUserData;
  }

  if (hasData(props.updatedBy ?? props)) {
    return (
      <>
        {convertToLink(
          props.updatedBy?.email ?? props.email,
          props.updatedBy?.userIdentifier ?? props.userIdentifier,
          props.updatedBy?.isDefault ?? props.isDefault
        )}
        {', '}
        {getSystemName(props.updatedBy?.sourceSystem ?? props.sourceSystem)}
      </>
    );
  } else {
    return <>{formatMessage(Messages.updatedByItem_NoData)}</>;
  }
};

export default UpdatedByItem;
