import { Enumeration } from './Enumeration';

class BankAccountTypesEnum extends Enumeration {
  Bankaccount = 'BankAccount';
  Bankgiro = 'BankGiro';
  Plusgiro = 'PlusGiro';

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const BankAccountTypes = new BankAccountTypesEnum();
