import React from 'react';
import { useGlobalAddressContext } from '../../AddressProvider';
import './Warning.css';
import {
  changeAddressForAgent,
  inheritAddressFromTeam,
} from '../../CommonAddressFunctions';
import { addressActions } from '../../AddressConstants';
import { formatMessage } from '../../../../Localization/formatMessage';
import Messages from '../../../../Localization/Messages';

const Warning = () => {
  const {
    agentCode,
    codePrefix,
    addressState,
    dispatch,
    setAddressAction,
  } = useGlobalAddressContext();

  return (
    <>
      <p className="warning-paragraph">
        {formatMessage(Messages.addressComponentWarning)}
        <br />
        {formatMessage(Messages.addressComponentWarningQuestion)}
      </p>
      <div className="relax-row">
        <div className="relax-col-3--xs"></div>
        <button
          className="relax-button"
          onClick={() => {
            inheritAddressFromTeam(agentCode, codePrefix, dispatch);
            setAddressAction(addressActions.View);
          }}>
          {formatMessage(Messages.addressComponentWarningInherit)}
        </button>
        <button
          className="relax-button relax-button--primary"
          onClick={() => {
            changeAddressForAgent(
              agentCode,
              codePrefix,
              addressState.selectedAddress.id,
              dispatch
            );
            setAddressAction(addressActions.View);
          }}>
          {formatMessage(Messages.addressComponentWarningContinue)}
        </button>
      </div>
    </>
  );
};

export default Warning;
