import './SalesCodeConversionsTable.css';

import React, { useState } from 'react';
import { TableIcons } from './TableIcons';
import {
  isDateInRange,
  DateRangeFilterComponent,
} from './DateRangeFilterComponent';
import MaterialTable from 'material-table';
import { formatMessage } from '../../Localization/formatMessage';
import Messages from '../../Localization/Messages';
import Check from '@material-ui/icons/Check';
import { convertServerDateTime } from '../../Helpers/Converter';

const SalesCodeConversionsTable = ({ data, action }) => {
  const [columns] = useState([
    {
      title: formatMessage(
        Messages.salesCodeConversionPageChannelNameInUserMetadata
      ),
      field: 'channelNameInUserMetadata',
      lookup: [...new Set(data?.map(x => x.channelNameInUserMetadata) ?? [])]
        .sort()
        .reduce((acc, curr) => {
          acc[curr] = curr;
          return acc;
        }, {}),
    },
    {
      title: formatMessage(Messages.salesCodeConversionPageGlCode),
      field: 'glCode',
    },
    {
      title: formatMessage(Messages.salesCodeConversionPageOldSalescode),
      field: 'oldSalesCode',
    },
    {
      title: formatMessage(Messages.salesCodeConversionPageNewSalescode),
      field: 'newSalesCode',
    },
    {
      title: formatMessage(
        Messages.salesCodeConversionPageChannelNameInWayPoint
      ),
      field: 'channelNameInWayPoint',
      lookup: [...new Set(data?.map(x => x.channelNameInWayPoint) ?? [])]
        .sort()
        .reduce((acc, curr) => {
          acc[curr] = curr;
          return acc;
        }, {}),
    },
    {
      title: formatMessage(
        Messages.salesCodeConversionPageConvertedSalesCodesMappedInRenewalStep
      ),
      field: 'isFutureChangeActive',
      type: 'boolean',
      render: data => (data.isFutureChangeActive ? <Check /> : <></>),
    },
    {
      title: formatMessage(Messages.salesCodeConversionPageConvertedDate),
      field: 'futureChangeStartDate',
      type: 'date',
      filterComponent: DateRangeFilterComponent,
      customFilterAndSearch: (filter, data) =>
        isDateInRange(
          filter.dateFrom,
          filter.dateTo,
          data.isFutureChangeActive ? data.futureChangeStartDate : null
        ),
      render: data =>
        data?.isFutureChangeActive && data?.newSalesCode
          ? convertServerDateTime(data.futureChangeStartDate)?.split(',')[0]
          : '',
    },
  ]);

  return (
    <MaterialTable
      icons={TableIcons}
      columns={columns}
      data={data ?? []}
      options={{
        showTitle: false,
        draggable: false,
        filtering: true,
        search: false,
        header: true,
        paging: true,
        pageSize: 10,
        grouping: false,
        sorting: true,
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        rowStyle: data =>
          data.isFutureChangeActive ? { backgroundColor: '#d0e6c3' } : {},
      }}
      actions={[
        data => ({
          icon: () => action(data),
        }),
      ]}
      localization={{
        header: {
          actions: '',
        },
        grouping: {
          placeholder: formatMessage(
            Messages.salesCodeConversionPageDragHeadersToGroupBy
          ),
        },
      }}
    />
  );
};
export default SalesCodeConversionsTable;
