import React, {
  createContext,
  memo,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useGlobalAddressContext } from '../../AddressProvider';
import StreetAddress from '../AddressFormats/StreetAddress';
import ForeignAddress from '../AddressFormats/ForeignAddress';
import PostBoxAddress from '../AddressFormats/PostBoxAddress';
import UnstructuredAddress from '../AddressFormats/UnstructuredAddress';
import FreeTextAddress from '../AddressFormats/FreeTextAddress';
import { addressFormats, addressActions } from '../../AddressConstants';
import {
  onSubmit,
  checkRequiredFields,
  areAddressesEqual,
  getCountryByCountryCodeFromList,
} from './AddressFormatSwitcherFunctions';
import { useGlobalContext } from '../../../../GlobalContext';

const AddressFormatSwitcherContext = createContext();
export const useAddressFormatContext = () =>
  useContext(AddressFormatSwitcherContext);

const AddressFormatSwitcher = ({ format }) => {
  const {
    setCanSubmit,
    addressAction,
    setAddressAction,
    addressState,
    agentCode,
    codePrefix,
    isModalOpenedOnTeamLevel,
    dispatch,
    countryList,
  } = useGlobalAddressContext();
  const globalContext = useGlobalContext();

  const [addressData, setAddressData] = useState(addressState.selectedAddress);

  const getCommonFields = propertyName => {
    const handleChange = e => {
      setAddressData({
        ...addressData,
        [e.target.name]: e.target.value,
      });
    };

    return {
      name: propertyName,
      key: addressData[propertyName],
      onBlur: handleChange,
      defaultValue: addressData[propertyName] ?? '',
    };
  };

  useEffect(() => {
    if (addressAction === addressActions.Create) {
      setAddressData({
        country: getCountryByCountryCodeFromList(
          globalContext.legalUnit,
          countryList
        ),
      });
    } else {
      setAddressData(addressState.selectedAddress);
    }
  }, [addressAction, addressState, globalContext.legalUnit, countryList]);

  const hasAddressChanged = (address, initialAddress, addressAction) => {
    if (addressAction === addressActions.Edit) {
      return !areAddressesEqual(address, initialAddress);
    }
    return true;
  };

  const useAddressValidation = ({
    format,
    address,
    initialAddress,
    addressAction,
  }) => {
    useEffect(() => {
      setCanSubmit(
        checkRequiredFields(address, format) &&
          hasAddressChanged(address, initialAddress, addressAction)
      );
    }, [format, address, addressAction, initialAddress]);
  };
  useAddressValidation({
    format,
    address: addressData,
    initialAddress: addressState.selectedAddress,
    addressAction,
    setCanSubmit,
  });

  return (
    <AddressFormatSwitcherContext.Provider
      value={{
        addressData,
        setAddressData,
        getCommonFields,
        addressFormat: format,
      }}>
      <form
        id="addressForm"
        onSubmit={e => {
          onSubmit(
            e,
            agentCode,
            codePrefix,
            isModalOpenedOnTeamLevel,
            addressData,
            setAddressAction,
            format,
            dispatch
          );
        }}
        autoComplete="off">
        <fieldset disabled={addressAction === addressActions.View}>
          <div className="relax-form max-width">
            {format === addressFormats.Street ? (
              <StreetAddress />
            ) : format === addressFormats.Foreign ? (
              <ForeignAddress />
            ) : format === addressFormats.POBox ? (
              <PostBoxAddress />
            ) : format === addressFormats.Unstructured ? (
              <UnstructuredAddress />
            ) : format === addressFormats.Freetext ? (
              <FreeTextAddress />
            ) : (
              <></>
            )}
          </div>
        </fieldset>
      </form>
    </AddressFormatSwitcherContext.Provider>
  );
};

export default memo(AddressFormatSwitcher);
