import React, { useState, memo, useEffect } from 'react';
import { addressActions, careOfFields } from '../../../../AddressConstants';
import { useAddressFormatContext } from '../../../AddressFormatSwitcher/AddressFormatSwitcher';
import { useGlobalAddressContext } from '../../../../AddressProvider';
import { formatMessage } from '../../../../../../Localization/formatMessage';
import Messages from '../../../../../../Localization/Messages';

const CareOfFields = () => {
  const { addressData, setAddressData } = useAddressFormatContext();
  const { addressAction, addressState } = useGlobalAddressContext();

  const [isShown, setIsShown] = useState(
    !!addressData.careOf?.prefix || !!addressData.careOf?.name
  );

  useEffect(() => {
    setIsShown(
      !!addressState.selectedAddress.careOf?.prefix ||
        !!addressState.selectedAddress.careOf?.name
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressAction]);

  const handleClick = () => {
    if (isShown) {
      const addressClone = { ...addressData };
      delete addressClone.careOf;
      setAddressData({ ...addressClone });
    }
    setIsShown(!isShown);
  };

  return (
    <>
      {addressAction === addressActions.Create ||
      addressAction === addressActions.Edit ? (
        <p className="link-button" onClick={handleClick}>
          {formatMessage(
            isShown
              ? Messages.addressComponentCoRemove
              : Messages.addressComponentCoAdd
          )}
        </p>
      ) : (
        <></>
      )}

      {isShown && (
        <div className="relax-row">
          <div className="relax-col-3--xs">
            <label>{formatMessage(Messages.addressComponentCoType)}</label>
            <div className="relax-select">
              <select
                name="cOPrefix"
                value={addressData.careOf?.prefix ?? 0}
                onChange={e => {
                  setAddressData({
                    ...addressData,
                    careOf: { ...addressData.careOf, prefix: e.target.value },
                  });
                }}>
                {careOfFields.map(field => (
                  <option key={field.id} value={field.value}>
                    {field.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="relax-col-5--xs">
            <label>{formatMessage(Messages.addressComponentCoName)}</label>
            <input
              className="max-width"
              type="text"
              onBlur={e => {
                setAddressData({
                  ...addressData,
                  careOf: { ...addressData.careOf, name: e.target.value },
                });
              }}
              key={addressData.careOf?.name}
              defaultValue={addressData.careOf?.name ?? ''}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(CareOfFields);
