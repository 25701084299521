import { formatMessage } from './formatMessage';
import { ResultErrorCode } from '../Enums/ResultErrorCode';
import Messages from './Messages';

export function getLocalizationForErrorMessage(...args) {
  // accept [code, argument]
  // accept {code, argument}
  const argsObject =
    args && args.length
      ? args[0] && typeof args[0] === 'object'
        ? args[0]
        : { code: args[0], argument: args[1] }
      : null;
  if (argsObject == null) {
    return null;
  }

  const code = argsObject.code ?? 0;
  const argument =
    argsObject.argument && typeof argsObject.argument === 'object'
      ? argsObject.argument
      : { value: argsObject.argument };

  var errorPrefix = 'errorMessage_';
  // supports message ids like errorMessage_1 or errorMessage_SalesIdIsAlreadyUsed
  var messageInfo =
    Messages[errorPrefix + code] ??
    Messages[errorPrefix + ResultErrorCode.keyOf(code)];
  if (!messageInfo) {
    return null;
  }

  var localizedMessage = formatMessage(messageInfo, argument);
  return localizedMessage;
}
