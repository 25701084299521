import { LocalizedEnumeration } from './Enumeration';

class BrokerCostTypeEnum extends LocalizedEnumeration {
  Property = 'Property';
  Liability = 'Liability';
  Person = 'Person';
  Transport = 'Transport';
  Casco = 'Casco';
  PersonalCommission = 'PersonalCommission';
  MotorCommission = 'MotorCommission';

  constructor() {
    super('brokerCostType_');
    return Object.freeze(this);
  }
}

export const BrokerCostType = new BrokerCostTypeEnum();
