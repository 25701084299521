import React, { memo } from 'react';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';
import InfoDetail from '../InfoDetail';
import { getFormattedAddress } from '../../Components/EditAddress/CommonAddressFunctions';

const SalesIdAddressName = ({ addresses }) => {
  return (
    <InfoDetail
      title={formatMessage(Messages.addressSalesIdAddressName)}
      body={getFormattedAddress(addresses?.postalAddress)}
    />
  );
};

export default memo(SalesIdAddressName);
