import React from 'react';
import InputElement from './InputElement';

const BankAccountTypeItem = props => {
  return (
    <InputElement
      labelForRadioBox={props.labelForRadioBox}
      name="bankAccountType"
      onChangeValue={val => props.onChangeValue(val)}
      value={props.bankAccountType}
      checked={props.checked}
      type="radio"
      inputWrapperClasses="relax-selection-controls bank-accounttype-radiogroup"
      inputElementClasses="relax-selection-control"
      radioBoxClasses="bank-accounttype-label"
    />
  );
};

export default BankAccountTypeItem;
