import { Enumeration } from './Enumeration';

class SalesCodeStateEnum extends Enumeration {
  Undefined = 'Undefined';
  NotActiveYet = 'NotActiveYet';
  Active = 'Active';
  ExpiringToday = 'ExpiringToday';
  Expired = 'Expired';
  constructor() {
    super();
    return Object.freeze(this);
  }
}
export const SalesCodeState = new SalesCodeStateEnum();
