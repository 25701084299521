import { ResultErrorCode } from '../Enums/ResultErrorCode';

export class ServiceErrorResolutionContext {
  constructor(errors, errorTexts, currentErrorIndex) {
    this.errors = errors;
    this.errorTexts = errorTexts;
    this.currentErrorIndex = currentErrorIndex;
  }

  errors = [];
  errorTexts = [];
  currentErrorIndex = 0;

  get currentIsLastError() {
    return this.errors?.length ?? 0 === this.currentErrorIndex;
  }

  get currentError() {
    return this.errors[this.currentErrorIndex];
  }

  get currentErrorText() {
    return this.errorTexts[this.currentErrorIndex];
  }
}

export class ServiceErrorHandlingResult {
  constructor(handled, stopHandlingOtherErrors = false) {
    this.handled = handled;
    this.stopHandlingOtherErrors = stopHandlingOtherErrors;
  }

  handled = false;
  stopHandlingOtherErrors = false;
}

export class ServiceErrorHandler {
  constructor(order, handlerFunction, ...errorCodes) {
    if (!errorCodes || !errorCodes.length) {
      errorCodes = [0];
    } else if (Array.isArray(errorCodes[0])) {
      errorCodes = errorCodes[0];
    }

    this.errorCodes = errorCodes.map(x => x.toString());
    this.handlerFunction = handlerFunction;
    this.order = order;
  }

  defaultErrorCode = '0';

  order = 0;
  errorCodes = [];
  handlerFunction = function(errorMessage, errorData, resolutionContext) {
    return true;
  };

  isApplicable(errorCode) {
    return this.errorCodes.find(x => x === errorCode);
  }

  handleError(resolutionContext) {
    const error = resolutionContext.currentError;
    if (!error) {
      return new ServiceErrorHandlingResult(false);
    }

    const errorCode = error.code?.toString() ?? this.defaultErrorCode;
    if (!this.isApplicable(errorCode)) {
      return new ServiceErrorHandlingResult(false);
    }

    const errorMessage = resolutionContext.currentErrorText;
    const handleResult = this.handlerFunction(
      errorMessage,
      {
        code: errorCode,
        message: error.message,
        argument: error.argument,
      },
      resolutionContext
    );
    if (typeof handleResult === 'boolean') {
      return new ServiceErrorHandlingResult(handleResult);
    }
    if (!handleResult) {
      return new ServiceErrorHandlingResult(false);
    }
    return new ServiceErrorHandlingResult(
      handleResult.handled,
      handleResult.stopHandlingOtherErrors
    );
  }
}

/**
 * Error handler which handles all error codes, except codes, passed to constructor
 */
export class ServiceExceptErrorHandler extends ServiceErrorHandler {
  isApplicable(errorCode) {
    return !super.isApplicable(errorCode);
  }
}

/**
 * Error handler which handles all codes
 */
export class ServiceAnyErrorHandler extends ServiceErrorHandler {
  isApplicable() {
    return true;
  }
}

/**
 * Error handler especially for errorCallback - it will handle all the codes and will have order 0
 */
export class ServiceCallbackErrorHandler extends ServiceAnyErrorHandler {
  constructor(handlerFunction) {
    // order 0 is used for errorCallback. If there is a need to run handler BEFORE callback, use negative order
    super(0, handlerFunction);
  }
}

export class UnknownAndBusinessErrorHandler extends ServiceErrorHandler {
  constructor(handlerFunction) {
    super(
      1000000,
      handlerFunction,
      ResultErrorCode.Undefined,
      ResultErrorCode.BusinessException
    );
  }
}

export class HttpErrorHandler extends ServiceErrorHandler {
  constructor(handlerFunction) {
    super(1000000, handlerFunction, ResultErrorCode.HttpResponseException);
  }
}

export class LoggingServiceErrorsHandler extends ServiceAnyErrorHandler {
  constructor() {
    super(-1000000, (errorMessage, errorData, resolutionContext) => {
      console.log('Server error:', errorMessage, errorData);
    });
  }
}
