import React, { useContext } from 'react';
import { BusinessArea } from './Enums/BusinessArea';

export const defaultGlobalContext = {
  pageRefreshed: true,
  legalUnit: null,
  businessArea: BusinessArea.Private,
};

export const GlobalContext = React.createContext(defaultGlobalContext);

export const useGlobalContext = () => useContext(GlobalContext);
