import React, { useState, useEffect, useContext, useCallback } from "react";
import { ServiceClient } from "../Services/ServiceClient";

export const useAuth = () => useContext(AuthContext);

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});

  const getUser = useCallback(async () => {
    setIsLoading(true); 

    ServiceClient.getUser(
      data => {
        setIsAuthenticated(data.isAuthenticated);
        if (data.isAuthenticated) {
          const user = parseClaims(data.claims);
          setUser(user);
        }
        setIsLoading(false); 
      },
      error => {
        console.error("Failed to fetch user", error);
        setIsLoading(false); 
      }
    );
  }, []); // Empty array ensures this callback is only created once

  const parseClaims = (claims) => {
    const user = {};
    claims.forEach(claim => {
      switch (claim.type) {
        case "sid":
          user.sessionId = claim.value;
          break;
        case "sub":
          user.userId = claim.value;
          break;
        case "auth_time":
          user.authTime = claim.value;
          break;
        case "idp":
          user.identityProvider = claim.value;
          break;
        case "preferred_username":
          user.preferredUsername = claim.value;
          break;
        case "name":
          user.fullName = claim.value;
          break;
        case "given_name":
          user.givenName = claim.value;
          break;
        case "family_name":
          user.familyName = claim.value;
          break;
        case "amr":
          user.authMethod = claim.value;
          break;
        default:
          break;
      }
    });
    return user;
  };

  useEffect(() => {
    getUser();
  }, [getUser]); // Include getUser in the dependency array

  const login = () => {
    const returnUrl = window.location.pathname + window.location.search;
    sessionStorage.setItem('returnUrl', returnUrl);
    window.location.href = "/auth/login";
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};