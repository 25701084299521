import { ServiceClient } from '../../../../Services/ServiceClient';
import { addressActions } from '../../AddressConstants';
import {
  changeAddressForAgent,
  inheritAddressFromTeam,
} from '../../CommonAddressFunctions';

export const handleClick = (
  agentCode,
  codePrefix,
  addressState,
  dispatch,
  setAddressAction,
  isModalOpenedOnTeamLevel
) => {
  if (isModalOpenedOnTeamLevel) {
    changeAddressForTeam(codePrefix, addressState.selectedAddress.id, dispatch);
    return;
  }
  if (addressState.uneditedData.isInherited) {
    changeAddress(
      agentCode,
      codePrefix,
      addressState.selectedAddress.id,
      addressState.teamAddress.id,
      dispatch,
      setAddressAction
    );
  } else {
    if (addressState.isInherited) {
      inheritAddressFromTeam(agentCode, codePrefix, dispatch);
    } else {
      changeAddress(
        agentCode,
        codePrefix,
        addressState.selectedAddress.id,
        addressState.teamAddress.id,
        dispatch,
        setAddressAction
      );
    }
  }
};

const changeAddressForTeam = (codePrefix, addressId, dispatch) => {
  ServiceClient.setAddressForTeam(codePrefix, addressId, () => {
    ServiceClient.getAddressListForTeam(codePrefix, list => {
      dispatch({ type: 'setSelectedAddress', list, isOpenedOnTeamLevel: true });
    });
  });
};

const changeAddress = (
  agentCode,
  codePrefix,
  addressId,
  teamAddressId,
  dispatch,
  setAddressAction
) => {
  if (addressId === teamAddressId) {
    setAddressAction(addressActions.Warning);
  } else {
    changeAddressForAgent(agentCode, codePrefix, addressId, dispatch);
  }
};
