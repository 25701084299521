export const addressReducer = (addressState, action) => {
  if (action.type === 'clearAddressFields') {
    return { ...addressState, selectedAddress: {} };
  }

  if (action.type === 'setInitialAddressStateTeam') {
    return {
      ...addressState,
      uneditedData: { address: action.teamAddressDto.postalAddress },
      selectedAddress: action.teamAddressDto.postalAddress,
      hasAddressUpdated: false,
    };
  }
  if (action.type === 'setInitialAddressStateAgent') {
    return {
      ...addressState,
      uneditedData: {
        address: action.agentAddressDto.address,
        isInherited: action.agentAddressDto.inherited,
      },
      selectedAddress: action.agentAddressDto.address,
      hasAddressUpdated: false,
      isInherited: action.agentAddressDto.inherited,
      teamAddress: action.teamAddressDto.postalAddress,
      updatedPersonData: {
        agent: {
          code: action.agentCode,
        },
      },
    };
  }

  if (action.type === 'resetSelectedAddress') {
    return {
      ...addressState,
      isInherited: addressState.uneditedData.isInherited,
      selectedAddress: addressState.uneditedData.address,
      hasAddressUpdated: false,
    };
  }

  if (action.type === 'setAddressList') {
    return {
      ...addressState,
      addressList: action.addressList,
    };
  }

  if (action.type === 'changeInheritance') {
    const hasAddressUpdated = addressState.uneditedData.isInherited
      ? addressState.isInherited
      : !addressState.isInherited;

    const newAddress = addressState.isInherited
      ? addressState.uneditedData.address
      : addressState.teamAddress;

    return {
      ...addressState,
      hasAddressUpdated,
      selectedAddress: newAddress,
      isInherited: !addressState.isInherited,
    };
  }

  if (action.type === 'changeHasAddressUpdated') {
    const address = addressState.addressList.find(
      x => x.id === action.addressId
    );

    const hasAddressUpdated = addressState.uneditedData.isInherited
      ? !addressState.isInherited
      : action.addressId !== addressState.uneditedData.address.id;

    return {
      ...addressState,
      hasAddressUpdated,
      selectedAddress: address,
    };
  }

  if (action.type === 'inheritAddressFromTeam') {
    return {
      ...addressState,
      selectedAddress: addressState.teamAddress,
      uneditedData: { address: addressState.teamAddress, isInherited: true },
      hasAddressUpdated: false,
      isInherited: true,
      addressList: action.list,
      updatedPersonData: {
        agent: {
          ...addressState.updatedPersonData.agent,
          isInherited: true,
        },
      },
    };
  }

  if (action.type === 'setSelectedAddress') {
    const updatedPersonData = action.isOpenedOnTeamLevel
      ? { teamAddress: addressState.selectedAddress }
      : {
          agent: {
            ...addressState.updatedPersonData.agent,
            isInherited: false,
          },
        };
    return {
      ...addressState,
      uneditedData: {
        address: addressState.selectedAddress,
        isInherited: false,
      },
      hasAddressUpdated: false,
      isInherited: false,
      addressList: action.list,
      updatedPersonData: updatedPersonData,
    };
  }

  if (action.type === 'setNewAddressForAgent') {
    return {
      ...addressState,
      selectedAddress: action.address,
      isInherited: false,
      hasAddressUpdated: false,
      uneditedData: {
        address: action.address,
        isInherited: false,
      },
      addressList: action.list,
      updatedPersonData: {
        agent: {
          ...addressState.updatedPersonData.agent,
          isInherited: false,
        },
      },
    };
  }

  if (action.type === 'setNewAddressForTeam') {
    return {
      ...addressState,
      selectedAddress: action.address,
      uneditedData: {
        address: action.address,
      },
      addressList: action.list,
      updatedPersonData: {
        teamAddress: action.address,
      },
    };
  }

  throw Error('Unknown action.', action);
};
