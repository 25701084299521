import React, { memo } from 'react';
import './CountrySelector.css';
import { useAddressFormatContext } from '../../../AddressFormatSwitcher/AddressFormatSwitcher';
import { useGlobalAddressContext } from '../../../../AddressProvider';
import { formatMessage } from '../../../../../../Localization/formatMessage';
import Messages from '../../../../../../Localization/Messages';

const CountrySelector = () => {
  const { addressData, setAddressData } = useAddressFormatContext();
  const { countryList } = useGlobalAddressContext();

  const getCountry = id => {
    return countryList.find(x => x.id === parseInt(id));
  };

  return (
    <>
      <label>
        {formatMessage(Messages.addressComponentCountrySelectorLabel)}
      </label>
      <div className="relax-select country-input">
        <select
          key={addressData.country?.id}
          defaultValue={addressData.country?.id}
          onChange={e => {
            setAddressData({
              ...addressData,
              country: getCountry(e.target.value),
            });
          }}>
          <option key={0} value={0}></option>
          {countryList.map(country => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(CountrySelector);
