import {
  COUNTRY_CODE_NORWAY,
  COUNTRY_CODE_SWEDEN,
  COUNTRY_CODE_DENMARK,
  COUNTRY_CODE_FINLAND,
  addressFormats,
} from '../../../../AddressConstants';
import { useEffect, useState } from 'react';
import { ServiceClient } from '../../../../../../Services/ServiceClient';

export const useAddressSearch = (
  query,
  countryCode,
  addressFormat,
  setIsOpen,
  setIsLoading
) => {
  const [addresses, setAddresses] = useState([]);
  const legalUnit = getLegalUnit(countryCode);

  useEffect(() => {
    let ignoreStaleResponse = false;
    setIsLoading(true);
    ServiceClient.searchBuildingRegistry(
      query,
      legalUnit,
      data => {
        if (data?.length > 0 && !ignoreStaleResponse) {
          const addresses = data
            .filter(
              address =>
                address.StreetName.length > 0 && address.StreetNumber.length > 0
            )
            .map(address => ({
              id: address.StreetId ?? address.AreaId,
              streetName: address.StreetName,
              houseNumber: address.StreetNumber
                ? address.StreetNumber.replace(/[A-Za-z]/g, '')
                : '',
              houseLetter: address.StreetNumber
                ? address.StreetNumber.replace(/[0-9]/g, '')
                : '',
              postal: {
                code: address.PostalCode,
                districtName: address.City,
              },
              format: addressFormat,
              displayText: `${address.City} ${address.PostalCode} ${address.StreetName} ${address.StreetNumber}`,
            }));
          if (addresses.length > 0) {
            setIsLoading(false);
            setAddresses(
              getAddressDataBasedOnFormat(
                addressFormat,
                addresses.sort(compare)
              )
            );
            setIsOpen(true);
          }
        } else {
          setIsLoading(false);
        }
      },
      () => {
        setIsLoading(false);
        setAddresses([]);
        setIsOpen(false);
      }
    );

    return () => {
      ignoreStaleResponse = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return addresses;
};

const getAddressDataBasedOnFormat = (format, addresses) => {
  if (format === addressFormats.Unstructured) {
    return addresses?.map(address => ({
      id: address.id,
      addressLine1: address.streetName,
      addressLine2: address.houseNumber + address.houseLetter,
      postal: address.postal,
      format: address.format,
    }));
  }
  if (format === addressFormats.Freetext) {
    return addresses?.map(address => ({
      id: address.id,
      additionalAddress:
        address.streetName + ' ' + address.houseNumber + address.houseLetter,
      postal: address.postal,
      format: address.format,
    }));
  }
  return addresses;
};

const compare = (a, b) => {
  let result = a.streetName.localeCompare(b.streetName);
  if (result !== 0) return result;
  result = compareStringsAsNumbers(a.houseNumber, b.houseNumber);
  if (result !== 0) return result;
  return a.houseLetter?.localeCompare(b.houseLetter);
};

const compareStringsAsNumbers = (a, b) => {
  if (parseInt(a) < parseInt(b) || a === undefined) return -1;
  if (parseInt(a) > parseInt(b) || b === undefined) return 1;
  return 0;
};

const getLegalUnit = countryCode => {
  const legalUnits = {
    [COUNTRY_CODE_NORWAY]: 200,
    [COUNTRY_CODE_SWEDEN]: 100,
    [COUNTRY_CODE_DENMARK]: 300,
    [COUNTRY_CODE_FINLAND]: 401,
  };
  return legalUnits[countryCode];
};
