import { formatMessage } from '../Localization/formatMessage';
import Messages from '../Localization/Messages';

/**
 * Common class for enumerations.
 *
 * @file This file defines Enumeration class
 * @author Daniil Barkov <daniil.barkov@if.lv>
 */
export class Enumeration {
  /**
   *
   * @param {Object} obj Object, containing enumeration properties
   */
  constructor(obj) {
    for (const key in obj) {
      this[key] = obj[key];
    }

    // check if it is super() call. If it is not, freeze
    return new.target === Enumeration ? Object.freeze(this) : this;
  }

  /**
   * Checks whether key is present in enum.
   *
   * @access public
   * @param {string} key Key of enum member
   * @returns {boolean} Presence of key in enum
   */
  hasKey = key => {
    return this.hasOwnProperty(key) && typeof this[key] !== 'function';
  };

  /**
   * Checks whether value is present in enum.
   *
   * @access public
   * @param {Object} value Value of enum member
   * @returns {boolean} Presence of value in enum
   */
  hasValue = value => {
    const foundElement = this._enumEntries.find(([key, val]) => val === value);
    return !!foundElement;
  };

  /**
   * Gets enum key by provided enum value.
   *
   * @access public
   * @param {Object} value Value of enum member
   * @returns {string} Key of enum member or undefined, if not found
   */
  keyOf = value => {
    const foundElement = this._enumEntries.find(([key, val]) => val === value);
    return foundElement && foundElement[0];
  };

  /**
   * Gets enum value by provided enum key.
   *
   * @access public
   * @param {string} key Key of enum member
   * @returns {Object} Value of enum member or undefined, if not found
   */
  valueOf = key => {
    return this.hasKey(key) ? this[key] : undefined;
  };

  /**
   * All key-value pairs of enumeration, sorted by value in ascending order.
   *
   * @access public
   * @type {{key: string, value: Object}[]}
   * @memberof Enumeration
   */
  get keysValues() {
    return this._sortedEnumEntries.map(([key, value]) => {
      return { key, value };
    });
  }

  /**
   * All keys of enumeration, sorted by value in ascending order.
   *
   * @access public
   * @type {string[]}
   * @memberof Enumeration
   */
  get keys() {
    return this._sortedEnumEntries.map(([key]) => key);
  }

  /**
   * All values of enumeration, sorted in ascending order.
   *
   * @access public
   * @type {string[]}
   * @memberof Enumeration
   */
  get values() {
    return this._sortedEnumEntries.map(([, value]) => value);
  }

  /**
   * All unsorted values of enumeration in form of [key, value][].
   *
   * @access private
   * @type {[string, Object][]}
   * @memberof Enumeration
   */
  get _enumEntries() {
    return Object.entries(this).filter(
      ([, value]) => typeof value !== 'function'
    );
  }

  /**
   * All sorted values of enumeration in form of [key, value][].
   *
   * @access private
   * @type {[string, Object][]}
   * @memberof Enumeration
   */
  get _sortedEnumEntries() {
    let sortableEnumEntries = [...this._enumEntries];
    sortableEnumEntries.sort(([, valueA], [, valueB]) => {
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    return sortableEnumEntries;
  }
}

/**
 * Class for enumerations having localization in Messages with keys in format "prefix_key".
 * Will work only in SalesAgentApp React components
 */
export class LocalizedEnumeration extends Enumeration {
  _messagesKeyPrefix = null;

  constructor(messagesKeyPrefix) {
    super();
    this._messagesKeyPrefix = messagesKeyPrefix;
  }

  /**
   * Finds localized string for provided enumeration value
   *
   * @access public
   * @param {Object} value
   * @returns {string}
   */
  valueToString(value) {
    if (!this.hasValue(value)) return null;
    const key = this.keyOf(value);
    return formatMessage(Messages[this._messagesKeyPrefix + key]);
  }

  /**
   * All localized keys, sorted by key in ascending order
   *
   * @access public
   * @type {string[]}
   * @memberof LocalizedEnumeration
   */
  get localizedKeys() {
    return this._sortedEnumEntries
      .slice(1)
      .map(([key]) => formatMessage(Messages[this._messagesKeyPrefix + key]));
  }

  /**
   * Finds localized string for provided enumeration key
   *
   * @access public
   * @param {string} key
   * @returns {string}
   */
  keyToString(key) {
    if (!this.hasKey(key)) return null;
    return formatMessage(Messages[this._messagesKeyPrefix + key]);
  }
}
