import React, { Component } from 'react';
import { Tile } from '../../Elements/Tiles';
import classNames from 'classnames';
import './HistoricalSalesCodes.css';
import { ExternalSalesCode } from '../../Elements/ExternalSalesCode';
import IconWithButton from '../../Elements/IconWithButton';
import { isSalesCodeHistorical } from '../../Helpers/SalesCodeHelper';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';

class HistoricalSalesCodes extends Component {
  state = { item: this.props.item };
  render() {
    const historicalSalesCodes = this.props.item?.salesCodes?.filter(
      isSalesCodeHistorical
    );
    return (
      <Tile
        embed={true}
        isUsingTitle={true}
        overflow={true}
        className={classNames('relax-lightGrey')}
        header={formatMessage(Messages.historicalSalesCodesTitle)}>
        {historicalSalesCodes && historicalSalesCodes.length > 0 ? (
          <table className="historical-sales-codes-table relax-table relax-table--striped">
            <thead>
              <tr>
                <th>{formatMessage(Messages.salesCode)}</th>
                <th>{formatMessage(Messages.costCenter)}</th>
                <th>{formatMessage(Messages.externalSalesCode)}</th>
                <th>{formatMessage(Messages.activationDate)}</th>
                <th>{formatMessage(Messages.endDate)}</th>
              </tr>
            </thead>
            <tbody>
              {historicalSalesCodes.map(salescode => (
                <tr key={salescode.id}>
                  <td>{salescode.salesCode}</td>
                  <td>{salescode.costCenter.name}</td>
                  <td>
                    <ExternalSalesCode>
                      {salescode.externalSalesCode}
                    </ExternalSalesCode>
                  </td>
                  <td>{salescode.validFrom}</td>
                  <td>{salescode.validTo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <IconWithButton
            title={formatMessage(Messages.noHistoricalSalesCode)}
            containerClass="historical-tile"
            showButton={false}
            iconName="list"></IconWithButton>
        )}
      </Tile>
    );
  }
}

export default HistoricalSalesCodes;
