import React, { useEffect, useReducer, useState } from 'react';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';
import './BrokerCommisionHistory.css';
import { ServiceClient } from '../../Services/ServiceClient';
import UpdatedByItem from '../../Elements/UpdatedByItem';
import { BrokerCostType } from '../../Enums/BrokerCostType';
import { convertServerDateTime } from '../../Helpers/Converter';
import { Loader } from '@customer/react-relax';

const BrokerCommisionHistory = ({ salesCode, customerNumber }) => {
  const [historyRecords, setHistoryRecords] = useState([]);

  function init() {
    addLoading();
    ServiceClient.getBrokerCostsActualRatesHistory(
      salesCode,
      customerNumber,
      records => {
        records = records
          .sort((a, b) => (a.effective > b.effective ? -1 : 1))
          .slice(1);
        setHistoryRecords(records);
        subLoading();
      },
      () => {
        subLoading();
      }
    );
  }
  useEffect(init, [salesCode, customerNumber]);

  const [loadingCounter, dispatchLoading] = useReducer((state, modifier) => {
    if (modifier === null) return 0;
    return state + modifier;
  }, 0);
  function addLoading() {
    dispatchLoading(1);
  }
  function subLoading() {
    dispatchLoading(-1);
  }

  function renderBool(b) {
    if (b) {
      return (
        <div className="relax-icon relax-icon-ui-check relax-icon--sm"></div>
      );
    } else {
      return null;
    }
  }

  function getRate(rateObject, rateType) {
    return rateObject.rates.find(x => x.rateType === rateType)?.rate;
  }

  return (
    <div className="broker-commission-history-container">
      <h3>{formatMessage(Messages.commissionPageHistoricalCommission)}</h3>

      <div className="table-container">
        {!!loadingCounter && (
          <div className="loading-container">
            <Loader />
          </div>
        )}

        <table className="relax-table relax-table--dense broker-commission-history-table">
          <thead>
            <tr>
              <th className="width-10">
                {formatMessage(Messages.commissionPageValidFrom)}
              </th>
              <th className="width-10">
                {formatMessage(Messages.commissionPageValidTo)}
              </th>
              <th className="width-auto">
                {formatMessage(Messages.commissionPageUpdatedBy)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageFeeAcceptance)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPagePersonalAcceptance)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageMotorAcceptance)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageProperty)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageLiability)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPagePerson)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPageTransport)}
              </th>
              <th className="width-8">
                {formatMessage(Messages.commissionPageComprehensive)}
              </th>
              <th className="width-6">
                {formatMessage(Messages.commissionPagePersonalCommission)}
              </th>
              <th className="width-7-375">
                {formatMessage(Messages.commissionPageMotorCommission)}
              </th>
            </tr>
          </thead>
          <tbody>
            {historyRecords.map(rate => (
              <tr
                className={
                  rate.customerNumber || !customerNumber ? '' : 'yellow'
                }>
                <td>{convertServerDateTime(rate.effective)}</td>
                <td>{convertServerDateTime(rate.expires)}</td>
                <td className="break-word">
                  <UpdatedByItem updatedBy={rate.updatedBy} />
                </td>
                <td>{renderBool(rate.useBrokerCost)}</td>
                <td>{renderBool(rate.usePersonalCommission)}</td>
                <td>{renderBool(rate.useMotorCommission)}</td>
                <td>{getRate(rate, BrokerCostType.Property)}</td>
                <td>{getRate(rate, BrokerCostType.Liability)}</td>
                <td>{getRate(rate, BrokerCostType.Person)}</td>
                <td>{getRate(rate, BrokerCostType.Transport)}</td>
                <td>{getRate(rate, BrokerCostType.Casco)}</td>
                <td>{getRate(rate, BrokerCostType.PersonalCommission)}</td>
                <td>{getRate(rate, BrokerCostType.MotorCommission)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default BrokerCommisionHistory;
