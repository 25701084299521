import React, { useState } from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import SearchPage from './Pages/SearchPage';
import PersonPage from './Pages/PersonPage';
import CommissionPage from './Pages/CommissionPage';
import { ChannelRepositoryInstance } from './Storage/ChannelRepository.js';
import { PresentationMode } from './Enums/PresentationMode';
import Error from './Pages/Error';
import { ServiceClient } from './Services/ServiceClient';
import SalesCodeConversionPage from './Pages/SalesCodeConversionPage';

function App(props) {
  const [errorCode, setError] = useState(null);
  // TODO this part is temporary here
  console.log('App loaded');
  if (!ChannelRepositoryInstance.initialized) {
    ServiceClient.getChannels(data => {
      ChannelRepositoryInstance.initialize(data);
    });
  }

  if (errorCode) return <Error code={errorCode} setError={setError} />;
  else
    return (
      <Switch>
        <Route
          path="/"
          exact
          render={() => <SearchPage navigationReset={props.navigationReset} />}
        />
        <Route
          path="/person/add"
          exact
          render={() => (
            <PersonPage
              mode={PresentationMode.Edit}
              navigationAppend={props.navigationAppend}
              navigationReset={props.navigationReset}
              setError={setError}
            />
          )}
        />
        <Route
          path="/person/view/:salesId/:editingSalesCode"
          render={routeprops => (
            <PersonPage
              navigationAppend={props.navigationAppend}
              navigationReset={props.navigationReset}
              setError={setError}
              {...routeprops}
            />
          )}
        />
        <Route
          path="/person/view/:salesId"
          render={routeprops => (
            <PersonPage
              navigationAppend={props.navigationAppend}
              navigationReset={props.navigationReset}
              setError={setError}
              {...routeprops}
            />
          )}
        />
        <Route
          path="/person/commission/:salesCode"
          render={routeprops => (
            <CommissionPage
              navigationAppend={props.navigationAppend}
              navigationReset={props.navigationReset}
              navigationList={props.navigationList}
              setError={setError}
              {...routeprops}
            />
          )}
        />
        <Route
          path="/salescodeconversions/"
          render={routeprops => (
            <SalesCodeConversionPage
              navigationAppend={props.navigationAppend}
              navigationReset={props.navigationReset}
              navigationList={props.navigationList}
              setError={setError}
              {...routeprops}
            />
          )}
        />
      </Switch>
    );
}

export default App;
