import { Enumeration } from './Enumeration';

class ResponsibleTypeEnum extends Enumeration {
  Internal = 'Internal';
  External = 'External';
  Broker = 'Broker';
  BrokerDesk = 'BrokerDesk';
  CarDealer = 'CarDealer';

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const ResponsibleType = new ResponsibleTypeEnum();
