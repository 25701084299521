import React, { memo } from 'react';
import { useGlobalAddressContext } from '../../AddressProvider';
import { addressActions } from '../../AddressConstants';
import { formatMessage } from '../../../../Localization/formatMessage';
import Messages from '../../../../Localization/Messages';

const EditCreateViewSetter = () => {
  const {
    addressAction,
    setAddressAction,
    dispatch,
  } = useGlobalAddressContext();

  return (
    <div className="relax-row">
      <div
        className="relax-col-3--xs clickable"
        onClick={() => {
          if (addressAction === addressActions.View) {
            setAddressAction(addressActions.Edit);
          }
        }}>
        {addressAction === addressActions.View &&
          formatMessage(Messages.addressComponentEditAddress)}
      </div>
      <div
        className="relax-col-3--xs relax-col-offset-6--xs clickable"
        onClick={() => {
          if (addressAction === addressActions.View) {
            setAddressAction(addressActions.Create);
            dispatch({
              type: 'clearAddressFields',
            });
          }
        }}>
        {addressAction === addressActions.View &&
          formatMessage(Messages.addressComponentCreateAddress)}
      </div>
    </div>
  );
};

export default memo(EditCreateViewSetter);
