import React from 'react';
import InfoDetail from './InfoDetail';
import { PresentationMode } from '../Enums/PresentationMode';
import Messages from '../Localization/Messages';
import { useIntl } from 'react-intl';
import { BankAccountTypes } from '../Enums/BankAccountType';
import BankAccountTypeItem from './BankAccountTypeItem';

const BankAccountType = props => {
  const formatMessage = useIntl().formatMessage;

  return props.mode === PresentationMode.Edit ? (
    <div className="relax-form-group">
      <label>{formatMessage(Messages.typeOfAccount)}</label>
      <div className="bank-accounttype-container">
        {BankAccountTypes.keysValues.map(({ key, value }) => (
          <BankAccountTypeItem
            key={key}
            labelForRadioBox={key}
            onChangeValue={props.onChangeValue}
            bankAccountType={value}
            checked={props.children === value}
          />
        ))}
      </div>
    </div>
  ) : (
    <>
      <InfoDetail
        title={formatMessage(Messages.bankName)}
        body={props.bankName}
      />
      {props.showBankAccountTypeInViewMode && (
        <InfoDetail
          title={formatMessage(Messages.typeOfAccount)}
          body={BankAccountTypes.keyOf(props.children)}
        />
      )}
    </>
  );
};

export default BankAccountType;
