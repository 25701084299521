import { Enumeration } from './Enumeration';

class ResultErrorCodeEnum extends Enumeration {
  Undefined = 0;
  SalesIdIsAlreadyUsed = 1;
  DuplicateExternalSalesCode = 2;
  SalesAgentWasNotFound = 3;
  SalesAgentHasNoSalesCodes = 4;
  SalesCodeAlreadyExpired = 5;
  RequiredFieldWasNotProvided = 6;
  UnknownBusinessArea = 7;
  SalesCodeWasNotFound = 8;
  SalesCodeNotBelongsToSalesAgent = 9;
  BusinessException = 10;
  HttpResponseException = 11;
  BankAccountInvalid = 12;
  TeamNotFound = 'TeamNotFound';

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const ResultErrorCode = new ResultErrorCodeEnum();
