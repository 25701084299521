import React, { useState } from 'react';
import './AddressView.css';
import { addressActions, addressFormats } from '../../AddressConstants';
import EditCreateViewSetter from '../EditCreateViewSetter';
import { useGlobalAddressContext } from '../../AddressProvider';
import AddressFormatSwitcher from '../AddressFormatSwitcher';
import AddressFormatSelector from '../AddressFormatSelector';
import AddressViewTop from '../AddressViewTop';

const AddressView = () => {
  const { addressAction, addressState } = useGlobalAddressContext();

  const [createAddressFormat, setCreateAddressFormat] = useState(
    addressFormats.Street
  );

  return (
    <div className="relax-grid--fluid">
      <AddressViewTop />
      <EditCreateViewSetter />
      {addressAction === addressActions.Create ? (
        <>
          <AddressFormatSelector
            addressFormat={createAddressFormat}
            setAddressFormat={setCreateAddressFormat}
          />
          <AddressFormatSwitcher format={createAddressFormat} />
        </>
      ) : (
        <AddressFormatSwitcher format={addressState.selectedAddress.format} />
      )}
    </div>
  );
};

export default AddressView;
