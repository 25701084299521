import React, { memo } from 'react';
import { updatePostalCode } from './PostalFieldsFunctions';
import { useAddressFormatContext } from '../../../AddressFormatSwitcher/AddressFormatSwitcher';
import { formatMessage } from '../../../../../../Localization/formatMessage';
import Messages from '../../../../../../Localization/Messages';

const PostalFields = () => {
  const { addressData, setAddressData } = useAddressFormatContext();

  return (
    <div className="relax-row">
      <div className="relax-col-3--xs">
        <label>
          {' '}
          {formatMessage(Messages.addressComponentPostalCodeLabel)}
        </label>
        <input
          key={addressData.postal?.code}
          className="max-width"
          defaultValue={addressData.postal?.code ?? ''}
          onBlur={e => {
            updatePostalCode(addressData, setAddressData, e.target.value);
          }}
          type="text"
          placeholder="Required"
        />
      </div>
      <div className="relax-col-5--xs">
        <label>
          {formatMessage(Messages.addressComponentPostalDistrictLabel)}
        </label>
        <input
          className="max-width"
          id="districtName"
          type="text"
          placeholder="Required"
          onBlur={e => {
            setAddressData({
              ...addressData,
              postal: {
                ...addressData.postal,
                districtName: e.target.value,
              },
            });
          }}
          key="districtName"
          name="districtName"
          defaultValue={addressData.postal?.districtName ?? ''}
        />
      </div>
    </div>
  );
};

export default memo(PostalFields);
