class LocalStorage {
  constructor() {
    this.storage = window.localStorage;
  }

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    const item = this.storage.getItem(key);
    if (item === null || item === undefined || item === "undefined") {
      return null;  // Return null if the item doesn't exist
    }
    try {
      return JSON.parse(item);
    } catch (e) {
      console.error('Error parsing JSON:', e);
      return null;  // Or handle the error as needed
    }
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }
}

export const localStorageInstance = new LocalStorage();

export default LocalStorage;
