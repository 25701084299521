import React from 'react';
import { formatMessage } from '../Localization/formatMessage';
import Messages from '../Localization/Messages';

const MissingValue = ({
  message,
  color = '#8C9BA5',
  highlightMissing = false,
}) => {
  message = formatMessage(Messages.missingValue);

  return (
    <i
      style={{
        color: highlightMissing ? '#CA3C3E' : color,
      }}>
      {message}
    </i>
  );
};

export default MissingValue;
