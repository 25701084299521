import React, { memo } from 'react';
import './ExistingAddressSelector.css';
import { useGlobalAddressContext } from '../../AddressProvider';
import { getFormattedAddress } from '../../CommonAddressFunctions';

const ExistingAddressSelector = () => {
  const { dispatch, addressState } = useGlobalAddressContext();

  return (
    <>
      <div className="relax-select max-width">
        <select
          name="existingAddressSelector"
          value={addressState.selectedAddress.id ?? 0}
          onChange={e => {
            dispatch({
              type: 'changeHasAddressUpdated',
              addressId: parseInt(e.target.value),
            });
          }}>
          {addressState.addressList?.map(x => (
            <option key={x.id} value={x.id}>
              {getFormattedAddress(x)}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(ExistingAddressSelector);
