import { Enumeration } from './Enumeration';

class UpdateSourceSystemEnum extends Enumeration {
  Unknown = 0;
  Waypoint = 1;
  SalesAgentApp = 2;
  MyBusiness = 3;
  IfLogin = 4;
  IfLoginSyncBatch = 5;

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const UpdateSourceSystem = new UpdateSourceSystemEnum();
