import classNames from 'classnames';
import React from 'react';

export const TileHeader = ({ title, children }) => {
  return (
    <span className="relax-tile-title">
      {title}
      {children}
    </span>
  );
};

export const Tile = ({
  children,
  embed = false,
  overflow = false,
  isUsingTitle = true,
  cols,
  rows,
  header,
  className,
  style = {},
  ...props
}) => {
  let styling = style;
  const overflowDefaultStyle = {
    maxHeight: '36rem',
    height: '36rem',
  };
  const embedWithTitleStyle = {
    paddingTop: '4rem',
  };

  if (overflow) {
    styling = { ...style, ...overflowDefaultStyle };
  }
  if (embed && isUsingTitle) {
    styling = { ...style, ...embedWithTitleStyle, ...overflowDefaultStyle };
  }

  const extraTileClassNames = { 'relax-embed': embed };

  if (cols) {
    extraTileClassNames[`relax-col-${cols}`] = true;
  }

  if (rows) {
    extraTileClassNames[`relax-row-${rows}`] = true;
  }

  const head = () => {
    if (isUsingTitle) return <TileHeader>{header}</TileHeader>;

    return null;
  };

  return (
    <div
      className={`relax-tile ${classNames(extraTileClassNames, className)}`}
      style={styling}
      {...props}>
      {head(header)}
      <div
        style={{
          overflowY: overflow ? 'auto' : 'hidden',
          maxHeight: '100%',
          height: '100%',
        }}>
        {children}
      </div>
    </div>
  );
};

export const ListTileItem = ({ children, style = {} }) => (
  <li className="relax-list-item" style={style}>
    {children}
  </li>
);

export const ListTileItemTitle = ({ title, children }) => (
  <span className="relax-list-itemTitle">
    {title}
    {children}
  </span>
);
