import React, { Component } from 'react';
import PersonInfo from './PersonInfo';
import HeroMenu from '../../Elements/HeroMenu';
import './PersonCard.css';

class PersonCard extends Component {
  state = {};
  render() {
    return (
      <header className="relax-tile relax-hero relax-embed relax-col-5 person-card">
        <HeroMenu {...this.props} />
        <PersonInfo {...this.props} />
      </header>
    );
  }
}
export default PersonCard;
