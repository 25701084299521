import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SalesCodeState } from '../../Enums/SalesCodeState';
import Messages from '../../Localization/Messages';

export default function SearchResultItem(props) {
  const { item, doRedirect } = props;

  return (
    <li
      tabIndex="0"
      className={classNames(
        `relax-grid search-result-list-item${
          item.isDraft ? '-with-adduser-icon' : ''
        }`
      )}
      onClick={doRedirect}
      key={item.salesId}>
      <div className="search-result-item-table relax-row">
        <dl className="relax-col-3--xs">
          <dt className="relax-label-heading">&nbsp;</dt>
          <dd>{item.name}</dd>
        </dl>

        <dl className="relax-col-2--xs">
          <dt className="relax-label-heading">
            <FormattedMessage {...Messages.userId} />
          </dt>
          <dd>{item.userId}</dd>
        </dl>

        <dl className="relax-col-2--xs">
          <dt className="relax-label-heading">
            <FormattedMessage {...Messages.salesId} />
          </dt>
          <dd className="sales-id-text">{item.salesId}</dd>
        </dl>

        <div className="relax-col-5--xs">
          <div className="relax-row">
            <div className="relax-col relax-grid">
              <div className="relax-row">
                <div className="relax-col-5--xs relax-label-heading">
                  <FormattedMessage {...Messages.salesCode} />
                </div>
                <div className="relax-col-5--xs relax-label-heading">
                  <FormattedMessage {...Messages.costCenter} />
                </div>
                <div className="relax-col-2--xs relax-label-heading">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>

          <div className="relax-row">
            <ul className="relax-col relax-grid">
              {!item.salesCodes ? (
                <li className="relax-row" key={0}>
                  <div className="relax-col-5--xs">&nbsp;</div>
                  <div className="relax-col-5--xs">&nbsp;</div>
                  <div className="relax-col-2--xs">
                    <button
                      className="relax-button relax-button--icon no-transition no-transform  add-user-icon-button"
                      type="button"></button>
                  </div>
                </li>
              ) : (
                item.salesCodes
                  ?.filter(x => x.state !== SalesCodeState.Expired)
                  .map((salescode, index) => (
                    <li className="relax-row" key={index}>
                      <div className="relax-col-5--xs sales-code-text">
                        {salescode.salesCode}
                      </div>
                      <div className="relax-col-5--xs">
                        {salescode.costCenter?.name}
                      </div>
                      <div className="relax-col-2--xs">
                        {index === 0 ? (
                          <button
                            className="relax-button relax-button--icon no-transition no-transform  edit-user-icon-button"
                            type="button"></button>
                        ) : (
                          ''
                        )}
                      </div>
                    </li>
                  ))
              )}
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
}
