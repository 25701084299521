import React from 'react';
import InfoDetail from './InfoDetail';
import InputElement from './InputElement';
import { PresentationMode } from '../Enums/PresentationMode';
import { useIntl } from 'react-intl';
import Messages from '../Localization/Messages';

const UserId = props => {
  const formatMessage = useIntl().formatMessage;
  return props.mode === PresentationMode.Edit ? (
    <InputElement
      title={formatMessage(Messages.userId)}
      name="userid"
      inputElementClasses="input-userid"
      onChangeValue={val => props.onChangeValue(val?.toUpperCase())}
      onBlur={props.onBlur}
      value={props.children}
      disabled={props.disabled}
      required={props.required}
      validationMessage={
        props.validationMessage ||
        formatMessage(Messages.invalid) + ' ' + formatMessage(Messages.userId)
      }
      forceInvalid={props.forceInvalid}
    />
  ) : (
    <InfoDetail
      title={formatMessage(Messages.userId)}
      className="userid-info-detail"
      body={props.children}
    />
  );
};

export default UserId;
