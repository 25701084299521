import React, { Component } from 'react';
import './Error.css';
import { ErrorMessages } from '../Enums/ErrorMessages';
import { Link } from 'react-router-dom';
import { formatMessage } from '../Localization/formatMessage';
import Messages from '../Localization/Messages';

class Error extends Component {
  state = {};

  setError() {
    if (this.props.setError) {
      this.props.setError(false);
    }
  }

  render() {
    const defaultTitle = formatMessage(Messages.errorPageTitle);
    const defaultDescription = '';
    const errorMessage = ErrorMessages.valueOf(this.props.code);

    const title = errorMessage
      ? formatMessage(errorMessage.title)
      : defaultTitle;
    const description = errorMessage
      ? formatMessage(errorMessage.description)
      : defaultDescription;

    return (
      <div className="relax-notification relax-notification--error relax-notification-hero">
        <div className="relax-notification-content">
          <div>
            <h1>{title}</h1>
            <span className="relax-error-code">
              <strong>
                {formatMessage(Messages.error)} #{this.props?.code}
              </strong>
              <span>
                <br />
                <span className="relax-error-message">{description}</span>
              </span>
            </span>
          </div>
          <ul className="list-actions">
            <li>
              <button
                onClick={() => {
                  this.setError.apply(this);
                  window.location.reload(true);
                }}
                className="relax-button relax-button--small relax-button-link relax-icon-ui-arrow-right--white error-action-button"
                type="button">
                {formatMessage(Messages.tryToRefresh)}
              </button>
            </li>
            <li>
              <Link
                to="/"
                className="relax-button relax-button--small relax-button-link relax-icon-ui-arrow-right--white error-action-button"
                type="button"
                onClick={this.setError.bind(this)}>
                {formatMessage(Messages.goToSearch)}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Error;
