import React from 'react';
import { Row, Col } from '@customer/react-relax';

const RowCol = props => {
  // this const MUST start with capital letter - React requirement
  const CustomTag = props.tag;
  const className = props.className;

  return (
    <Row>
      {CustomTag != null ? (
        <Col>
          <CustomTag className={className}>{props.children}</CustomTag>
        </Col>
      ) : (
        <Col className={className}>{props.children}</Col>
      )}
    </Row>
  );
};

export default RowCol;
