import { Enumeration } from './Enumeration';

class BusinessAreaEnum extends Enumeration {
  Private = 'private';
  Commercial = 'commercial';
  Industrial = 'industrial';

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const BusinessArea = new BusinessAreaEnum();

export const isSameBusinessArea = (a, b) => {
  // Consider Commercial and Industrial as same #86266
  if (
    (a === BusinessArea.Commercial || a === BusinessArea.Industrial) &&
    (b === BusinessArea.Commercial || b === BusinessArea.Industrial)
  )
    return true;

  return a === b;
};
