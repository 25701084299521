import './AddressSearch.css';
import React, { memo, useState } from 'react';
import { useAddressFormatContext } from '../../../AddressFormatSwitcher/AddressFormatSwitcher';
import { useAddressSearch } from './AddressSearchFunctions';
import { Loader } from '@customer/react-relax';
import { getFormattedAddress } from '../../../../CommonAddressFunctions';
import { formatMessage } from '../../../../../../Localization/formatMessage';
import Messages from '../../../../../../Localization/Messages';

const AddressSearch = () => {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    addressData,
    setAddressData,
    addressFormat,
  } = useAddressFormatContext();

  const list = useAddressSearch(
    query,
    addressData.country?.code,
    addressFormat,
    setIsOpen,
    setIsLoading
  );

  return (
    <>
      <div className="relax-row address-search-input">
        <div className="relax-col-11--xs">
          <input
            className="max-width"
            placeholder={formatMessage(
              Messages.addressComponentSearchPlaceholder
            )}
            type="text"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        </div>
        <div
          className={`relax-col-1--xs ${
            isOpen ? 'relax-icon-ui-chevron-up' : 'relax-icon-ui-chevron-down'
          } clickable`}
          onClick={() => setIsOpen(!isOpen)}></div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isOpen && (
            <ul className="address-search-container">
              {list.map(address => (
                <li
                  key={address.id}
                  className="address-search-row clickable"
                  onClick={e => {
                    setAddressData({
                      ...addressData,
                      ...address,
                    });
                    setIsOpen(false);
                  }}>
                  {getFormattedAddress(address)}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </>
  );
};

export default memo(AddressSearch);
