import React from 'react';
import InfoDetail from './InfoDetail';
import InputElement from './InputElement';
import { PresentationMode } from '../Enums/PresentationMode';
import Messages from '../Localization/Messages';
import { useIntl } from 'react-intl';

const Name = props => {
  const formatMessage = useIntl().formatMessage;
  return props.mode === PresentationMode.Edit ? (
    <InputElement
      title={formatMessage(Messages.name)}
      name="name"
      inputElementClasses="input-name"
      onChangeValue={val => props.onChangeValue(val)}
      value={props.children}
      required={props.required}
      validationMessage={
        formatMessage(Messages.invalid) + formatMessage(Messages.name)
      }
      disabled={props.disabled}
    />
  ) : (
    <InfoDetail
      title={formatMessage(Messages.name)}
      body={<span>{props.children}</span>}
    />
  );
};

export default Name;
