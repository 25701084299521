import React, { Component } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import './InputElement.css';

class InputElement extends Component {
  state = {
    value: '',
  };

  constructor(props) {
    super(props);
    this.inputControl = React.createRef();
    this.id = _.uniqueId('input-');
  }

  componentDidMount() {
    this.setState({
      value: this.props.value ?? '',
    });

    this.inputControl.current.setCustomValidity(
      this.props.forceInvalid ? this.props.validationMessage : ''
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value ||
      (this.props.forceValueUpdate && prevProps !== this.props)
    ) {
      this.setState({
        value: this.props.value ?? '',
      });
    }

    if (
      prevProps.forceInvalid !== this.props.forceInvalid ||
      prevProps.validationMessage !== this.props.validationMessage
    ) {
      this.inputControl.current.setCustomValidity(
        this.props.forceInvalid ? this.props.validationMessage : ''
      );
    }
  }

  render() {
    const {
      title,
      name,
      disabled,
      inputElementClasses,
      placeholder,
      type,
      required,
      pattern,
      validationMessage,
      onBlur,
      inputWrapperClasses,
      labelForRadioBox,
      radioBoxClasses,
      checked,
      readonly,
    } = this.props;
    const id = this.id;
    return (
      <div
        className={
          inputWrapperClasses ? inputWrapperClasses : 'relax-form-group'
        }>
        {title ? <label>{title}</label> : ''}
        <input
          ref={this.inputControl}
          className={classnames(inputElementClasses, 'relax-text-field')}
          name={name}
          placeholder={placeholder}
          value={this.state.value}
          onChange={this.localOnChangeValue.bind(this)}
          onBlur={onBlur}
          disabled={disabled}
          type={type}
          required={required}
          pattern={pattern}
          id={id}
          checked={checked}
          readOnly={readonly ? 'readOnly' : null}
        />
        {labelForRadioBox ? (
          <label htmlFor={id} className={classnames(radioBoxClasses)}>
            {labelForRadioBox}
          </label>
        ) : (
          ''
        )}
        <div className="relax-validation-message">{validationMessage}</div>
      </div>
    );
  }

  localOnChangeValue(event) {
    if (this.props.readonly) return;

    const val = event.target.value;
    this.setState({
      value: val,
    });

    if (this.props.onChangeValue) {
      this.props.onChangeValue(val);
    }
  }
}

export default InputElement;
