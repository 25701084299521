import MissingValue from './MissingValue';
import classNames from 'classnames';
import React from 'react';
import './InfoDetail.css';

const getBody = (body, loading) => {
  if (loading) return null;

  if (!body) return <MissingValue />;

  return body;
};

const InfoDetail = ({ title, body, hide, className, loading = false }) => {
  return (
    <dl
      className={hide ? 'hide' : classNames('relax-dl info-detail', className)}>
      <dt>{title}</dt>
      <dd>
        <span>{getBody(body, loading)}</span>
      </dd>
    </dl>
  );
};

export default InfoDetail;
