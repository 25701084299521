import React from 'react';
import { useGlobalAddressContext } from '../../AddressProvider';
import { formatMessage } from '../../../../Localization/formatMessage';
import Messages from '../../../../Localization/Messages';

const AddressInheritanceCheckBox = () => {
  const { dispatch, addressState } = useGlobalAddressContext();

  return (
    <>
      <div className="relax-selection-controls">
        <input
          id="checkBox"
          type="checkbox"
          className="relax-selection-control"
          checked={addressState.isInherited}
          onChange={() => {
            dispatch({ type: 'changeInheritance' });
          }}
        />
        <label htmlFor="checkBox">
          {formatMessage(Messages.addressComponentCheckBoxLabel)}
        </label>
      </div>
    </>
  );
};

export default AddressInheritanceCheckBox;
