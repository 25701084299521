let _intl = null;

export function setIntlForFormatMessage(intl) {
  if (
    !intl ||
    !intl.formatMessage ||
    typeof intl.formatMessage !== 'function'
  ) {
    throw new Error(
      `Incorrect input to setIntlForFormatMessage, expected function, got ${typeof intl}`
    );
  }
  _intl = intl;
}

export function formatMessage(...args) {
  if (!_intl) {
    throw new Error('Intl is not set by setIntlForFormatMessage');
  }
  return _intl.formatMessage.apply(this, args);
}
