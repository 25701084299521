import React, { memo } from 'react';
import CountrySelector from '../CommonFields/CountrySelector';
import { useAddressFormatContext } from '../../AddressFormatSwitcher';
import PostalFields from '../CommonFields/PostalFields';
import CareOfFields from '../CommonFields/CareOfFields/';
import { formatMessage } from '../../../../../Localization/formatMessage';
import Messages from '../../../../../Localization/Messages';

const PostBoxAddress = () => {
  const { getCommonFields } = useAddressFormatContext();

  return (
    <>
      <CareOfFields />

      <div className="relax-row">
        <div className="relax-col-5--xs">
          <label>{formatMessage(Messages.addressComponentPostBoxNumber)}</label>
          <input type="text" {...getCommonFields('number')}></input>
        </div>
        <div className="relax-col-5--xs">
          <label>{formatMessage(Messages.addressComponentPostBoxName)}</label>
          <input type="text" {...getCommonFields('facilityName')}></input>
        </div>
      </div>
      <CountrySelector />
      <PostalFields />
    </>
  );
};

export default memo(PostBoxAddress);
