import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  SecondaryButton,
  PrimaryButton,
  Grid,
  Row,
  Col,
  Loader,
} from '@customer/react-relax';
import RowCol from '../../Elements/RowCol';
import SalesID from '../../Elements/SalesID';
import Name from '../../Elements/Name';
import './CopyCostCenter.css';
import CheckboxElement from '../../Elements/CheckboxElement';
import { groupBy } from '../../Helpers/groupBy';
import InputElement from '../../Elements/InputElement';
import { debounce } from 'lodash';
import { PresentationMode } from '../../Enums/PresentationMode';
import { Redirect } from 'react-router-dom';
import { NotificationType } from '../../Enums/NotificationType';
import { Notification } from '../../Elements/Notification';
import { isSalesCodeActive } from '../../Helpers/SalesCodeHelper';
import { ConvertErrorToNewLines } from '../../Helpers/Converter';
import { ServiceClient } from '../../Services/ServiceClient';
import { ServiceErrorHandlingResult } from '../../Services/ServiceErrorHandler';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';

const defaultState = {
  // data:
  salesId: '',
  name: '',
  costCenters: [],
  // validation:
  salesIdExists: false,
  salesIdUniquenessChecked: false,
  atLeastOneCostCenterSelected: false,
  isValid: false,
  // UI and technical fields:
  isLoading: false,
  allCostCentersChecked: true,
  renderRedirect: false,
  errorMessage: null,
  autoCloseErrorMessage: true,
  salesIdData: null,
  salesIdValidatingStarted: false, // needed not to show format calidation error for sales Id right from beginning
  dataPurged: false, // shows that state should be reset when window opened next time
  resultFromAD: null,
  nameWasChangedSinceAdRequest: false,

  // "enableValidation feature" flag - if true, red validation messages will be shown
  enableValidation: false,
};

class CopyCostCenter extends Component {
  static propTypes = {
    isShown: PropTypes.bool.isRequired,
    setIsShown: PropTypes.func.isRequired,
    salesCodes: PropTypes.any,
  };

  state = { ...defaultState };

  // part of enableValidation feature
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.checkboxesHidden = React.createRef();
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    const costCenters = this.mapToCostCenters(this.props.salesCodes);
    this.setState({
      costCenters,
      allCostCentersChecked: true,
      atLeastOneCostCenterSelected: !!costCenters.length,
    });
  }

  componentDidUpdate(prevProps) {
    // if component is invisible, do nothing
    if (!this.props.isShown) {
      // if salesCodes were changed while window was closed
      if (this.props.salesCodes !== prevProps.salesCodes) {
        this.setState({ dataPurged: true });
      }

      return;
    }

    if (this.state.dataPurged) {
      // clean start
      const costCenters = this.mapToCostCenters(this.props.salesCodes);
      this.setStateAndValidate({
        ...defaultState,
        costCenters,
        atLeastOneCostCenterSelected: !!costCenters.length,
      });
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isShown}
        onRequestClose={this.onCancel.bind(this)}
        title={formatMessage(Messages.copyCostCenterTitle)}
        boxClasses="copy-cost-center"
        closeButtonText=""
        buttons={[
          <SecondaryButton key="cancel" onClick={this.onCancel.bind(this)}>
            {formatMessage(Messages.cancel)}
          </SecondaryButton>,
          <PrimaryButton
            key="save"
            onClick={this.onSave.bind(this)}
            disabled={!this.state.isValid}>
            {formatMessage(Messages.copyAndCreate)}
          </PrimaryButton>,
        ]}>
        {/* form tag is a part of enableValidation feature */}
        <form ref={this.form}>
          <Grid>
            <RowCol tag="h4">
              {formatMessage(Messages.chooseCostCenterToCopy)}
            </RowCol>
            <RowCol className="table-container">
              {/* Table with cost centers */}
              <table className="relax-table relax-table--striped">
                <thead>
                  <tr>
                    <th>
                      <CheckboxElement
                        checked={this.state.allCostCentersChecked}
                        onChangeValue={this.setAllCostCentersChecked.bind(
                          this
                        )}>
                        {formatMessage(Messages.chooseAll)}
                      </CheckboxElement>
                    </th>
                    <th>{formatMessage(Messages.costCenter)}</th>
                    <th>{formatMessage(Messages.externalSalesCode)}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.costCenters.map(cc => (
                    <tr key={cc.id}>
                      <td>
                        <CheckboxElement
                          checked={cc.checked}
                          onChangeValue={checked =>
                            this.setOneCostCenterChecked.bind(this)(checked, cc)
                          }></CheckboxElement>
                      </td>
                      <td>{cc.costCenter}</td>
                      <td>
                        <InputElement
                          value={cc.externalSalesCode}
                          onChangeValue={val => (cc.externalSalesCode = val)}
                          disabled={!cc.checked}
                          placeholder={formatMessage(
                            Messages.optional
                          )}></InputElement>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </RowCol>
            <RowCol className="checkbox-validation-container">
              {/* Hack for validating whether at least one checbox checked; Part of enableValidation feature */}
              <input
                type="text"
                required={this.state.enableValidation}
                value={this.state.atLeastOneCostCenterSelected ? 'value' : ''}
                onChange={() => {}}
              />
              <div className="relax-validation-message">
                {formatMessage(Messages.requiredSelectAtLeastOneCostCenter)}
              </div>
            </RowCol>
            <RowCol>
              <h4>{formatMessage(Messages.createNewUser)}</h4>
              {this.state.errorMessage && this.salesIdIsValid() ? (
                <Notification
                  type={NotificationType.Error}
                  hideCloseButton={true}
                  autoClose={this.state.autoCloseErrorMessage}
                  closedCallback={() => this.setState({ errorMessage: null })}>
                  {this.state.errorMessage}
                </Notification>
              ) : (
                !this.state.resultFromAD &&
                this.state.salesIdValidatingStarted &&
                !this.state.isLoading &&
                this.salesIdIsValid() && (
                  <Notification
                    type={NotificationType.Warning}
                    hideCloseButton={true}
                    autoClose={false}>
                    {formatMessage(Messages.notificationUserIdNotFoundInAD)}
                  </Notification>
                )
              )}
            </RowCol>
            <Row>
              {/* SalesID field and loading */}
              <Col>
                <SalesID
                  mode={PresentationMode.Edit}
                  onChangeValue={val => {
                    this.setStateAndValidate({
                      salesId: val,
                      salesIdUniquenessChecked: false,
                      salesIdValidatingStarted: true,
                      errorMessage: null,
                    });
                    this.debouncedValidateSalesIdUniqueness(val);
                  }}
                  onBlur={() => {
                    this.debouncedValidateSalesIdUniqueness.flush(
                      this.state.salesId
                    );
                  }}
                  required={this.state.enableValidation}
                  forceInvalid={!this.salesIdIsValid()}
                  validationMessage={this.getSalesIdValidationMessage()}>
                  {this.state.salesId}
                </SalesID>
              </Col>
              <Col
                className={
                  this.state.isLoading ? 'loader-visible' : 'loader-hidden'
                }>
                <Loader />
              </Col>
            </Row>
            <RowCol></RowCol>
            <RowCol>
              {/* Name field */}
              <Name
                mode={PresentationMode.Edit}
                onChangeValue={val => {
                  this.setStateAndValidate({
                    name: val,
                    nameWasChangedSinceAdRequest: true,
                  });
                }}
                required={this.state.enableValidation}>
                {this.state.name}
              </Name>
            </RowCol>
          </Grid>
        </form>
        {/* Redirect to PersonPage of new sales id */}
        {this.state.renderRedirect && (
          <Redirect
            push
            to={{
              pathname: `/person/view/${this.state.salesId}`,
              state: {
                notifications: [
                  {
                    type: NotificationType.Success,
                    text: formatMessage(
                      Messages.notificationSuccessCopyCostCenter
                    ),
                  },
                ],
                item: this.state.salesIdData,
              },
            }}
          />
        )}
      </Modal>
    );
  }

  loadingCounter = 0;

  setLoading() {
    this.loadingCounter++;
    if (this.loadingCounter > 0) {
      this.setStateAndValidate({ isLoading: true });
    }
  }

  releaseLoading(state) {
    this.loadingCounter--;
    const newState = { ...state };
    let saveState = !!state;
    if (this.loadingCounter <= 0) {
      newState.isLoading = false;
      saveState = true;
    }
    if (saveState) {
      this.setStateAndValidate(newState);
    }
  }

  // part of enableValidation feature
  validate() {
    return this.form.current.reportValidity();
  }

  setStateAndValidate(state, callback) {
    let newState = { ...state, isValid: this.isValidCustom(state) };
    this.setState(newState, callback);
  }

  isValidCustom(state) {
    // checks custom validity
    state = { ...this.state, ...state };
    const isValid =
      state.atLeastOneCostCenterSelected &&
      state.name &&
      !state.isLoading &&
      state.salesIdUniquenessChecked &&
      !state.salesIdExists &&
      this.salesIdIsValid(state);
    return isValid;
  }

  salesIdIsValid(state) {
    state = state ?? this.state;
    return (
      !state.salesIdValidatingStarted || this.checkSalesIdFormat(state.salesId)
    );
  }

  checkSalesIdFormat(salesId) {
    return salesId && salesId.match(/^[a-zA-Z0-9]{3,8}$/);
  }

  getSalesIdValidationMessage() {
    return formatMessage(Messages.validationSalesId);
  }

  mapToCostCenters(salesCodes) {
    // get distinct cost centers from sales codes
    const filteredSalesCodes = (salesCodes ?? []).filter(isSalesCodeActive);
    const distinctCostCenters = Object.values(
      groupBy(
        filteredSalesCodes.map(sc => {
          return { ...sc, costCenterId: sc.costCenter.id };
        }),
        'costCenterId'
      )
    ).map(x => x[0]);

    const costCenters = distinctCostCenters.map(x => {
      return {
        checked: true,
        costCenter: `${x.costCenter.name} (${x.costCenter.code})`,
        externalSalesCode: '',
        id: x.costCenter.id,
      };
    });
    return costCenters;
  }

  setAllCostCentersChecked(checked) {
    // checking/unchecking all cost centers
    let newCostCenters = [];
    this.state.costCenters.forEach(cc =>
      newCostCenters.push({ ...cc, checked })
    );

    this.setStateAndValidate({
      costCenters: newCostCenters,
      allCostCentersChecked: checked,
      atLeastOneCostCenterSelected: checked && !!this.state.costCenters.length,
    });
  }

  setOneCostCenterChecked(checked, costCenter) {
    // update checked in this.state.costCenters
    let newCostCenters = [...this.state.costCenters];
    newCostCenters[newCostCenters.indexOf(costCenter)] = {
      ...costCenter,
      checked,
    };
    let newState = {
      costCenters: newCostCenters,
      atLeastOneCostCenterSelected: newCostCenters.some(x => x.checked),
    };

    // calculating allCostCentersChecked
    if (checked && !this.state.allCostCentersChecked) {
      newState.allCostCentersChecked =
        newCostCenters.filter(cc => cc.checked).length ===
        newCostCenters.length;
    } else if (!checked) {
      newState.allCostCentersChecked = false;
    }

    this.setStateAndValidate(newState);
  }

  onCancel() {
    this.props.setIsShown(false);
  }

  onSave() {
    if (this.isValidCustom()) {
      this.createSalesIdAndSalesCodes(() => {
        this.props.setIsShown(false);
      });
    }
  }

  cancellationSource = null;

  debouncedValidateSalesIdUniqueness = debounce(
    this.validateSalesIdUniqueness,
    500
  );

  validateSalesIdUniqueness(salesId) {
    // do not spam server with invalid sales ids
    if (!this.checkSalesIdFormat(salesId)) {
      return;
    }

    this.setLoading();
    if (this.cancellationSource) this.cancellationSource.cancel('New input');
    this.cancellationSource = ServiceClient.createCancellationToken();

    ServiceClient.checkCodePrefixUniqueness(
      salesId,
      this.cancellationSource,
      () => {
        // sales id not found - good
        this.searchAD(this.state.salesId, () => {
          this.releaseLoading({
            salesIdExists: false,
            salesIdUniquenessChecked: true,
            errorMessage: null,
            autoCloseErrorMessage: true,
          });
        });
      },
      () => {
        // sales id found - bad
        this.releaseLoading({
          salesIdExists: true,
          salesIdUniquenessChecked: true,
          errorMessage: formatMessage(Messages.errorMessageUserIdAlreadyExist),
          autoCloseErrorMessage: false,
        });
      },
      () => {
        // request cancelled by new input
        this.releaseLoading({
          salesIdExists: false,
          salesIdUniquenessChecked: false,
        });
      },
      () => {
        // sales id doing something else - bad
        this.releaseLoading({
          salesIdExists: true,
          salesIdUniquenessChecked: true,
          errorMessage: formatMessage(Messages.errorMessageUnexpectedError),
          autoCloseErrorMessage: true,
        });
      }
    );
  }

  searchAD(query, callback) {
    this.setLoading();
    ServiceClient.getUserDetailsFromADbyUserID(query, result => {
      var newState = {
        resultFromAD: result,
        nameWasChangedSinceAdRequest: false,
      };
      if (result) {
        newState.name = this.getFullNameFromADresult(result);
      } else if (!this.state.nameWasChangedSinceAdRequest) {
        newState.name = '';
      }
      this.releaseLoading(newState);
      callback();
    });
  }

  getFullNameFromADresult(resultFromAD) {
    resultFromAD = resultFromAD ?? this.state.resultFromAD;
    return resultFromAD
      ? `${resultFromAD.firstName} ${resultFromAD.lastName}`
      : null;
  }

  createSalesIdAndSalesCodes(successCallback) {
    const data = this.generateCreateRequest();
    this.setLoading();

    ServiceClient.createTeam(
      this.state.salesId.toUpperCase(),
      data,
      result => {
        successCallback();
        this.releaseLoading({
          dataPurged: true,
          renderRedirect: true,
          salesIdData: result,
        });
      },
      (message, error, context) => {
        const errorMessage = ConvertErrorToNewLines(context.errorTexts);
        this.releaseLoading({ errorMessage: errorMessage });
        return new ServiceErrorHandlingResult(true, true);
      }
    );
  }

  generateCreateRequest() {
    return {
      name: this.state.name,
      salesCodes: this.state.costCenters
        .filter(cc => cc.checked)
        .map(cc => {
          return {
            costCenterId: cc.id,
            externalSalesCode: cc.externalSalesCode,
          };
        }),
    };
  }
}

export default CopyCostCenter;
