import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuListItemWrapper } from '@customer/react-relax';

class DisableableMenuListItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    // not used, but present in @customer/react-relax/MenuListItem
    icon: PropTypes.string,
    className: PropTypes.any,
    actionClassName: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  render() {
    /* TODO if someone finds a way to add "disabled" to button inside of @customer/react-relax/MenuListItem, replace whole construction with MenuListItem */
    return (
      <MenuListItemWrapper className={this.props.className}>
        <button
          id={this.props.id}
          className={this.props.actionClassName}
          onClick={this.props.onClick}
          disabled={this.props.disabled}>
          {this.props.name}
        </button>
      </MenuListItemWrapper>
    );
  }
}

export default DisableableMenuListItem;
