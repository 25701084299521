import React from 'react';
import InfoDetail from './InfoDetail';
import InputElement from './InputElement';
import { PresentationMode } from '../Enums/PresentationMode';
import Messages from '../Localization/Messages';
import { useIntl } from 'react-intl';

const BankAccountNumber = props => {
  const formatMessage = useIntl().formatMessage;
  return props.mode === PresentationMode.Edit ? (
    <InputElement
      title={formatMessage(Messages.bankAccount)}
      name="bankAccount"
      inputElementClasses="bankaccountnumber"
      onChangeValue={val => props.onChangeValue(val)}
      value={props.children}
      required={props.required}
      validationMessage={
        formatMessage(Messages.invalid) + formatMessage(Messages.bankAccount)
      }
    />
  ) : (
    <InfoDetail
      title={formatMessage(Messages.bankAccount)}
      body={props.children}
    />
  );
};

export default BankAccountNumber;
