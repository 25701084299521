import { Enumeration } from './Enumeration';

class BrokerCostExceptionSeverityEnum extends Enumeration {
  Error = 0;
  Warning = 1;

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const BrokerCostExceptionSeverity = new BrokerCostExceptionSeverityEnum();
