import React, { useEffect, useRef, useState } from 'react';
import './CustomerDropdown.css';
import SearchDropdownField from '../../Elements/SearchDropdownField';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';
import { ServiceClient } from '../../Services/ServiceClient';
import { debounce } from 'lodash';

const CustomerDropdown = ({
  customers = [] && null,
  searchOnServer = false,
  selectedCustomerNumber = null,
  excludedCustomerNumbers = [],
  onCustomerSelect = customer => {},
  useAddButton = false,
  showHeader = true,
  onAddClick = customer => {},
  onFilter = (filteredCustomers, text) => {},
  serverRequestDebounceTimeout = 500,
}) => {
  const [customersList, setCustomersList] = useState([]);
  const [customersFromServer, setCustomersFromServer] = useState([]);
  const [selectedCustomerVal, setSelectedCustomerVal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterYieldNoResults, setFilterNoResults] = useState(false);

  const [debouncedFindCustomer, setDebouncedFindCustomer] = useState(null);

  const searchDropdownRef = useRef();

  function findCustomer(text, currentCustomersFromServer) {
    if (!text) {
      setIsLoading(false);
      return;
    }

    ServiceClient.findCustomers(text, result => {
      result = result.filter(x => !excludedCustomerNumbers.includes(x.number));
      result.forEach(x => (x.isLoadedFromServer = true));
      // in order to avoid multiple updating from empty array to empty array
      if (currentCustomersFromServer.length !== 0 || result.length !== 0)
        setCustomersFromServer([...result]);
      setIsLoading(false);
    });
  }

  function prepareCustomer(c) {
    // keep only fields which wil be exposed outside of control
    return {
      number: c.number,
      legalNumber: c.legalNumber,
      name: c.name,
    };
  }

  useEffect(() => {
    // need to exclude duplicates
    var customersHash = {};
    (customers ?? []).forEach(x => (customersHash[x?.number ?? 'NONE'] = true));

    var newCustomersList = [];
    if (useAddButton) {
      // If need to use add button, then we create special element in beginning of customers list
      var defaultAddRecord = {
        isAddButton: true,
        customDropdownItemRender: item => (
          <li
            className="add-customer-li"
            onClick={() => {
              searchDropdownRef.current.blur();
              setSelectedCustomerVal(null);
              onAddClick(prepareCustomer(item));
            }}>
            <a
              href="/"
              onClick={e => {
                e.preventDefault();
              }}>
              <div className="add-customer-container">
                <span className="add-customer-text">
                  {formatMessage(Messages.customerDropDownAddCustomer)}
                </span>
                {item && (
                  <>
                    <div className="add-customer-column">{item.number}</div>
                    <div className="add-customer-column">
                      {item.legalNumber}
                    </div>
                    <div className="add-customer-column">{item.name}</div>
                  </>
                )}
              </div>
            </a>
          </li>
        ),
      };
      var alreadyCreatedRecord =
        customersList.find(x => x.isAddButton) ?? defaultAddRecord;
      var singleCustomerFromServer =
        customersFromServer?.length === 1 ? customersFromServer[0] : null;
      if (singleCustomerFromServer && filterYieldNoResults) {
        // should not recreate that record, because it is compared by link in SearchDropdownField, so updating it:
        Object.assign(alreadyCreatedRecord, singleCustomerFromServer);
      } else {
        // remove all keys which not present in default record (basically, keys related to customer)
        Object.keys(alreadyCreatedRecord).forEach(
          key =>
            Object.keys(defaultAddRecord).includes(key) ||
            delete alreadyCreatedRecord[key]
        );
      }
      newCustomersList.push(alreadyCreatedRecord);
    } else {
      newCustomersList.push(
        ...(customersFromServer ?? []).filter(x => !customersHash[x.number])
      );
    }
    newCustomersList.push(...(customers ?? []));

    setCustomersList(newCustomersList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, useAddButton, customersFromServer, filterYieldNoResults]);

  useEffect(() => {
    var customer = customers?.find(x => x.number === selectedCustomerNumber);
    setSelectedCustomerVal(customer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerNumber]);

  useEffect(() => {
    setDebouncedFindCustomer(() =>
      debounce(findCustomer, serverRequestDebounceTimeout)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverRequestDebounceTimeout]);

  return (
    <SearchDropdownField
      ref={searchDropdownRef}
      className="customer-drop-down"
      items={customersList}
      selectedItem={selectedCustomerVal}
      isLoading={isLoading}
      showHeader={showHeader}
      placeholder={formatMessage(Messages.customerDropDownPlaceholder)}
      searchSubjectName={formatMessage(Messages.customerDropDownSearchSubject)}
      labelFunction={item => {
        return item?.name;
      }}
      filterFunction={(item, text) => {
        if (!item) return false;
        if (item.isAddButton) return true;
        if (!item.legalNumber && !item.name && !item.number) return false;
        if (!text) return true;
        // if format 123456789 or 12345-1234, then search by legal number
        if (text.match(/^\d+(-\d*)?$/i)) {
          return (
            item.legalNumber &&
            item.legalNumber.includes(text.replaceAll('-', ''))
          );
        }
        // if format is WP0000123, then search by customer number, ignoring 0-es after "WP"
        if (text.match(/^WP\d*$/i)) {
          var clipZeroesRegex = /^WP0*/i;
          return (
            item.number &&
            item.number
              .replace(clipZeroesRegex, 'WP')
              .includes(text.replace(clipZeroesRegex, 'WP'))
          );
        }
        // Name search
        return (
          item.name && item.name.toLowerCase().includes(text.toLowerCase())
        );
      }}
      itemToColumnsConverter={item => {
        return [item.number, item.legalNumber, item.name];
      }}
      onItemSelect={item => {
        if (!item) return;
        if (item.isAddButton) {
          if (!onAddClick) return;

          setSelectedCustomerVal(item);
          onAddClick(prepareCustomer(item));
        } else if (onCustomerSelect) {
          setSelectedCustomerVal(item);
          onCustomerSelect(prepareCustomer(item));
        }
      }}
      onFilter={(filteredResult, state) => {
        if (
          searchOnServer &&
          (!filteredResult.length ||
            filteredResult.every(x => x.isAddButton)) &&
          debouncedFindCustomer
        ) {
          setIsLoading(true);
          debouncedFindCustomer(state.text, customersFromServer);
        }

        if (state.text) {
          onFilter(filteredResult, state.text);
        } else {
          onFilter(null, null);
        }
        setFilterNoResults(
          filteredResult.length === 1 &&
            filteredResult.every(x => x.isAddButton)
        );
      }}
    />
  );
};

export default CustomerDropdown;
