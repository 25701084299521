import React from 'react';
import classnames from 'classnames';
import './IconWithButton.css';

const IconWithButton = props => {
  // props:
  //  title
  //  buttonText
  //  alignTop
  //  containerClass
  //  iconName
  //  showButton
  //  onButtonClick

  return (
    <div
      className={classnames(
        props.containerClass,
        'icon-with-button',
        props.alignTop
          ? 'icon-with-button-align-top'
          : 'icon-with-button-align-center'
      )}>
      <header
        className={`relax-placeholder-header relax-icon-ui-${props.iconName}`}>
        <h2 className="relax-placeholder-title">{props.title}</h2>
      </header>
      {props.showButton ? (
        <div className="relax-placeholder-actions">
          <button
            type="button"
            className="relax-button relax-button--primary"
            onClick={props.onButtonClick}
            disabled={props.disabled}>
            {props.buttonText}
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default IconWithButton;
