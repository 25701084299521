import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  WarningButton,
  SecondaryButton,
  Grid,
} from '@customer/react-relax';
import './RevokeConfirmation.css';
import RowCol from '../../Elements/RowCol';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';

class RevokeConfirmation extends Component {
  static propTypes = {
    isShown: PropTypes.bool.isRequired,
    setIsShown: PropTypes.func.isRequired,
    revokeCallback: PropTypes.func.isRequired,
    salesCodes: PropTypes.any,
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isShown}
        onRequestClose={this.onCancel.bind(this)}
        title={formatMessage(Messages.revokeConfirmationTitle)}
        boxClasses="revoke-confirmation"
        closeButtonText=""
        buttons={[
          <SecondaryButton key="cancel" onClick={this.onCancel.bind(this)}>
            {formatMessage(Messages.cancel)}
          </SecondaryButton>,
          <WarningButton key="revoke" onClick={this.onRevoke.bind(this)}>
            {formatMessage(Messages.yesRevoke)}
          </WarningButton>,
        ]}>
        <Grid>
          <RowCol tag="h4">
            {formatMessage(Messages.revokeConfirmationMessage)}
          </RowCol>
          <RowCol>
            <table className="sales-codes-table relax-table relax-table--striped">
              <thead>
                <tr>
                  <th>{formatMessage(Messages.salesCode)}</th>
                  <th>{formatMessage(Messages.costCenter)}</th>
                  <th>{formatMessage(Messages.externalSalesCode)}</th>
                  <th>{formatMessage(Messages.activationDate)}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.salesCodes.map(sc => (
                  <tr key={sc.id}>
                    <td>{sc.salesCode}</td>
                    <td>{`${sc.costCenter.name} (${sc.costCenter.code})`}</td>
                    <td>{sc.externalSalesCode}</td>
                    <td>{sc.validFrom}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </RowCol>
        </Grid>
      </Modal>
    );
  }

  onCancel() {
    this.props.setIsShown(false);
  }

  onRevoke() {
    this.props.setIsShown(false);
    this.props.revokeCallback();
  }
}

export default RevokeConfirmation;
