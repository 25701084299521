import { formatMessage } from '../../Localization/formatMessage';
import Messages from '../../Localization/Messages';
import { ServiceClient } from '../../Services/ServiceClient';
import { addressFormats } from './AddressConstants';

export const changeAddressForAgent = (
  agentCode,
  codePrefix,
  addressId,
  dispatch
) => {
  ServiceClient.setAddressForAgent(agentCode, addressId, () => {
    ServiceClient.getAddressListForTeam(codePrefix, list => {
      dispatch({
        type: 'setSelectedAddress',
        list,
        isOpenedOnTeamLevel: false,
      });
    });
  });
};

export const inheritAddressFromTeam = (agentCode, codePrefix, dispatch) => {
  ServiceClient.inheritAddressFromTeam(agentCode, () => {
    ServiceClient.getAddressListForTeam(codePrefix, list => {
      dispatch({ type: 'inheritAddressFromTeam', list });
    });
  });
};

export const getFormattedAddress = address => {
  if (address === undefined) return formatMessage(Messages.missingValue);
  if (address.format === addressFormats.Street)
    return `${address.format} ${address.streetName} ${address.houseNumber ||
      ''}${address.houseLetter || ''} `;
  if (address.format === addressFormats.Foreign)
    return `${address.format} ${address.addressLine1} ${address.addressLine2 ||
      ''} ${address.addressLine3 || ''}`;
  if (address.format === addressFormats.POBox)
    return `${address.format} ${address.number || ''} ${address.facilityName ||
      ''}`;
  if (address.format === addressFormats.Freetext)
    return `${address.format} ${address.additionalAddress || ''}`;
  if (address.format === addressFormats.Unstructured)
    return `${address.format} ${address.addressLine1} ${address.addressLine2 ||
      ''} ${address.addressLine3 || ''}`;
};
