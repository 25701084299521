import React, { useEffect, useState } from 'react';
import { Modal, Loader } from '@customer/react-relax';
import './BankAccountHistory.css';
import { ServiceClient } from '../../Services/ServiceClient';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';
import { BankAccountTypes } from '../../Enums/BankAccountType';
import UpdatedByItem from '../../Elements/UpdatedByItem';
import { convertServerDateTime } from '../../Helpers/Converter';

const BankAccountHistory = props => {
  const [loading, setLoading] = useState(true);
  const [historyItems, setHistoryItemsState] = useState([]);

  // On initialization / params change
  useEffect(() => {
    if (!props.isShown) return;

    if (props.salesId) {
      ServiceClient.getDefaultBankAccountLog(props.salesId, items => {
        setHistoryItems(items);
        setLoading(false);
      });
    } else {
      ServiceClient.getBankAccountHistoryForAgent(
        props.salesCode,
        items => {
          setHistoryItems(items);
          setLoading(false);
        }
      );
    }

    return () => {
      setLoading(true);
      setHistoryItems([]);
    };
  }, [props.isShown, props.salesCode, props.salesId]);

  function setHistoryItems(items) {
    // sort in descending order
    if (items?.sort) {
      items.sort((a, b) => (a.updated < b.updated ? 1 : -1));
    }
    setHistoryItemsState(items);
  }

  function onCancel() {
    props.setIsShown(false);
  }

  function bankAccountTypeToString(bankAccountType) {
    if (BankAccountTypes.hasValue(bankAccountType))
      return BankAccountTypes.keyOf(bankAccountType);
    return <i>{formatMessage(Messages.invalid)}</i>;
  }

  return (
    <>
      <Modal
        boxClasses="bank-account-history-modal"
        isOpen={props.isShown}
        onRequestClose={onCancel}
        closeButtonText={formatMessage(Messages.close)}
        title={formatMessage(Messages.bankAccountLogModalTitle, {
          salesCode: props.salesCode || props.salesId,
        })}>
        {loading && <Loader />}
        {!loading && (
          <>
            <table className="relax-table relax-table--striped">
              <thead>
                <tr>
                  <th>{formatMessage(Messages.bankAccountLogBankAccount)}</th>
                  <th>
                    {formatMessage(Messages.bankAccountLogBankAccountType)}
                  </th>
                  <th>{formatMessage(Messages.bankAccountLogBankName)}</th>
                  {props.salesCode && (
                    <th>{formatMessage(Messages.bankAccountLogShared)}</th>
                  )}
                  <th>{formatMessage(Messages.bankAccountLogUpdated)}</th>
                  <th>{formatMessage(Messages.bankAccountLogUpdatedBy)}</th>
                </tr>
              </thead>
              <tbody>
                {historyItems.map(item => (
                  <tr key={item.updated + item.bankAccount?.number}>
                    <td>{item.bankAccount?.number}</td>
                    <td>{bankAccountTypeToString(item.bankAccount?.type)}</td>
                    <td>{item.bankAccount?.bankName}</td>
                    {props.salesCode && (
                      <td>
                        {!item.salesCode
                          ? formatMessage(Messages.yes)
                          : formatMessage(Messages.no)}
                      </td>
                    )}
                    <td>{convertServerDateTime(item.updated)}</td>
                    <td>
                      <UpdatedByItem updatedBy={item.updatedBy} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Modal>
    </>
  );
};

export default BankAccountHistory;
