import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { Loader } from '@customer/react-relax';
import './Callback.css';
import { formatMessage } from '../Localization/formatMessage';
import Messages from '../Localization/Messages';
import { AuthContext } from '../context/AuthContext';

const defaultUrl = '/';

const absoluteUrlToLocal = url => {
  if (url.startsWith(defaultUrl)) return url;
  return url.replace(new URL(url).origin, '');
};

const Callback = () => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { isAuthenticated, isLoading, user, login } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        const storedUrl = sessionStorage.getItem('returnUrl');
        const redirectUrl = absoluteUrlToLocal(storedUrl || defaultUrl);
        sessionStorage.removeItem('returnUrl'); // Clean up storage
        setRedirectUrl(redirectUrl);
      } else {
        login();
      }
    }
  }, [isAuthenticated, isLoading, user, login]);

  if (isLoading) {
    return (
      <div className="callback-main">
        <Loader />
        <div className="callback-sub">
          <span>{formatMessage(Messages.redirecting)} ...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      {!!redirectUrl && <Redirect push={false} to={redirectUrl} />}
      <div className="callback-main">
        <Loader />
        <div className="callback-sub">
          <span>{formatMessage(Messages.redirecting)} ...</span>
        </div>
      </div>
    </>
  );
};

export default Callback;