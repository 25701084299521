import React, { Component} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import App from './App';
import Callback from './Pages/Callback';
import Error from './Pages/Error';
import { defaultGlobalContext, GlobalContext } from './GlobalContext';
import {
  UnknownAndBusinessErrorHandler,
  LoggingServiceErrorsHandler,
  HttpErrorHandler,
} from './Services/ServiceErrorHandler';
import { ServiceClient } from './Services/ServiceClient';
import { ServerErrorNotification } from './Elements/Notification';
import { NotificationType } from './Enums/NotificationType';
import GlobalHeaderWithLoader from './Elements/GlobalHeader';
import { IntlProvider, createIntl } from 'react-intl';
import { defaultLanguageCode, storageKeys } from './config/variables';
import translations from './Localization/translations/translations.json';
import { localStorageInstance } from './Storage/LocalStorage';
import Messages from './Localization/Messages';
import { setIntlForFormatMessage } from './Localization/formatMessage';
import { convertMessagesForIntl } from './Helpers/Converter';
import { BusinessArea } from './Enums/BusinessArea';
import { useAuth } from './context/AuthContext';
import { Loader } from '@customer/react-relax';

const messages = {
  en: convertMessagesForIntl(Messages, x => x.defaultMessage),
  sv: convertMessagesForIntl(translations, x => x.langs.sv),
  da: convertMessagesForIntl(translations, x => x.langs.da),
};


function getLocaleInfo(locale) {
  const navigatorOrSavedLang =
    localStorageInstance.getItem(storageKeys.locale) ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;
  locale = locale ?? navigatorOrSavedLang ?? defaultLanguageCode;

  return {
    locale: locale,
    messages: messages[locale],
  };
}

function AuthInfo(props) {
  const { isAuthenticated, login, isLoading } = useAuth();
  if (isLoading) {
    return <Loader />; // Don't render anything while loading
  } else if (isAuthenticated) {
    console.log('')
    return <App
      navigationAppend={props.navigationAppend}
      navigationReset={props.navigationReset}
      navigationList={props.headerMenu}
    />;
  }
  else {
    login();
    return null
  }
};

class RootRoutes extends Component {
  constructor(props) {
    super(props);
    setIntlForFormatMessage(createIntl(this.state.localeInfo));
  }

  state = {
    headerMenu: [{ id: 'salesAgent', address: '/' }],
    globalContext: defaultGlobalContext,
    localeInfo: getLocaleInfo(),
  };

  previousPath = null;
  errorHandlers = [];

  componentDidMount() {
    var legalUnit = localStorageInstance.getItem(storageKeys?.legalUnit) ?? 'SE';
    var businessArea =
      localStorageInstance.getItem(storageKeys?.businessArea) ?? 'Private';

    this.updateGlobalContext({
      legalUnit: legalUnit,
      businessArea: businessArea,
    });

    this.historyUnlisten = this.props.history.listen((location, action) => {
      // on SPA navigation we need to clean state "pageRefreshed"
      this.updateGlobalContext({ pageRefreshed: false });
      this.previousPath = location.pathname;
      //  and clear error, if any
      this.setState({ httpErrorCode: null });
    });

    this.errorHandlers.push(
      ServiceClient.registerGlobalErrorHandler(
        new UnknownAndBusinessErrorHandler(
          (errorMessage, errorData, resolutionContext) => {
            this.setState({ errorData: errorData });
          }
        )
      )
    );
    this.errorHandlers.push(
      ServiceClient.registerGlobalErrorHandler(
        new HttpErrorHandler((errorMessage, errorData, resolutionContext) => {
          this.setState({ httpErrorCode: errorData.argument ?? 500 });
        })
      )
    );
    this.errorHandlers.push(
      ServiceClient.registerGlobalErrorHandler(
        new LoggingServiceErrorsHandler()
      )
    );
  }

  componentWillUnmount() {
    this.historyUnlisten();
    this.errorHandlers.forEach(x =>
      ServiceClient.unregisterGlobalErrorHandler(x)
    );
  }

  updateGlobalContext(context, callback) {
    this.setState(
      {
        globalContext: { ...this.state.globalContext, ...context },
      },
      callback
    );
  }

  onChangeLanguage(lang) {
    if (!lang) {
      return;
    }

    const localeInfo = getLocaleInfo(lang);
    // should be BEFORE setState
    setIntlForFormatMessage(createIntl(localeInfo));

    this.setState({ localeInfo });
    localStorageInstance.setItem(storageKeys.locale, lang);
  }

  handleUserPreferences(preferences) {
    localStorageInstance.setItem(storageKeys.legalUnit, preferences.legalUnit);
    localStorageInstance.setItem(
      storageKeys.businessArea,
      preferences.businessArea
    );
    this.updateGlobalContext({
      legalUnit: preferences.legalUnit,
      businessArea: BusinessArea.valueOf(preferences.businessArea),
    });
  }

  render() {
    return (
      <IntlProvider
        locale={this.state.localeInfo.locale}
        messages={this.state.localeInfo.messages}>
        <GlobalContext.Provider value={this.state.globalContext}>
          <GlobalHeaderWithLoader
            breadcrumbs={this.state.headerMenu}
            onChangeLanguage={this.onChangeLanguage.bind(this)}
            handleUserPreferences={this.handleUserPreferences.bind(this)}
          />
          <nav className="relax-menubar relax-left"></nav>
          <main className="relax-main">
            {this.state.errorData && (
              <ServerErrorNotification
                type={NotificationType.Error}
                inline={false}
                closedCallback={() => this.setState({ errorData: null })}
                timeout={10000}
                error={this.state.errorData}
              />
            )}
            {this.state.httpErrorCode ? (
              <Error
                code={this.state.httpErrorCode}
                setError={x => this.setState({ httpErrorCode: x })}
              />
            ) : (

              <Switch>
                <Route path="/callback" component={Callback} />
                <Route path="/error" exact component={Error} />
                <Route path="/" render={() => <AuthInfo 
                  navigationAppend={this.navigationAppend}
                  navigationReset={this.navigationReset}
                  headerMenu={this.state.headerMenu}
                />} 
                />
              </Switch>
            )}
          </main>
        </GlobalContext.Provider>
      </IntlProvider>
    );
  }

  //TODO Change header navigation menu to breadcrumbs library
  navigationAppend = (name, address) => {
    if (
      this.state.headerMenu.filter(x => x.id === name && x.address === address)
        .length > 0
    )
      return;
    this.setState(prevState => ({
      headerMenu: [...prevState.headerMenu, { id: name, address }],
    }));
  };

  navigationReset = callback => {
    this.setState(
      {
        headerMenu: [{ id: 'salesAgent', address: '/' }],
      },
      callback
    );
  };
}

export default withRouter(RootRoutes);
