import React, { useCallback, useState } from 'react';
import InfoDetail from './InfoDetail';
import InputElement from './InputElement';
import { PresentationMode } from '../Enums/PresentationMode';
import { useIntl } from 'react-intl';
import Messages from '../Localization/Messages';
import { validateEmail } from '../Helpers/Validator';
import { debounce } from 'lodash';

const Email = props => {
  const formatMessage = useIntl().formatMessage;
  const [isValidEmail, setIsValidEmail] = useState(true);
  const emailValidatorEvent = value => {
    setIsValidEmail(validateEmail(value));
  };
  const debouncedValidateEmail = useCallback(
    debounce(emailValidatorEvent, 500),
    []
  );

  return !props.mode || props.mode === PresentationMode.Edit ? (
    <InputElement
      title={props.title ?? formatMessage(Messages.email)}
      placeholder={props.placeholder}
      name={props.name}
      inputElementClasses={props.className}
      onChangeValue={val => {
        props.onChangeValue(val);
        debouncedValidateEmail(val);
      }}
      onBlur={() => debouncedValidateEmail.flush()}
      value={props.children}
      disabled={props.disabled}
      required={props.required}
      validationMessage={
        props.validationMessage ||
        formatMessage(Messages.invalid) + ' ' + formatMessage(Messages.email)
      }
      forceInvalid={!isValidEmail}
    />
  ) : (
    <InfoDetail
      title={formatMessage(Messages.email)}
      body={<span>{props.children}</span>}
    />
  );
};

export default Email;
