import React, { memo } from 'react';
import AddressSearch from '../CommonFields/AddressSearch';
import CountrySelector from '../CommonFields/CountrySelector';
import { useAddressFormatContext } from '../../AddressFormatSwitcher';
import { useGlobalAddressContext } from '../../../AddressProvider';
import { addressActions } from '../../../AddressConstants';
import PostalFields from '../CommonFields/PostalFields';
import CareOfFields from '../CommonFields/CareOfFields/';
import { formatMessage } from '../../../../../Localization/formatMessage';
import Messages from '../../../../../Localization/Messages';

const FreeTextAddress = () => {
  const { getCommonFields } = useAddressFormatContext();
  const { addressAction } = useGlobalAddressContext();

  return (
    <>
      {addressAction === addressActions.Create && <AddressSearch />}

      <CareOfFields />

      <label>
        {formatMessage(Messages.addressComponentFreeTextAdditionalLine)}
      </label>
      <input type="text" {...getCommonFields('additionalAddress')} />
      <CountrySelector />
      <PostalFields />
    </>
  );
};

export default memo(FreeTextAddress);
