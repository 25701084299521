import React from 'react';

export const ConvertErrorToNewLines = error => {
  return error.map((x, i) => <p key={i}>{x}</p>);
};

export const capitalizeFirstWord = word => {
  return !word || word === ''
    ? null
    : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const capitalizeAllWords = word => {
  if (!word || word === '') return;
  const splittedStr = word.toLowerCase().split(' ');
  for (var i = 0; i < splittedStr.length; i++) {
    splittedStr[i] = capitalizeFirstWord(splittedStr[i]);
  }
  return splittedStr.join(' ');
};

export const convertMessagesForIntl = function(data, getTranslationFunc) {
  var res = {};
  Object.values(data).forEach(x => (res[x.id] = getTranslationFunc(x)));
  return res;
};

export const legalUnitSwitch = legalUnit =>
  ({
    SE: 'Sweden',
    DK: 'Denmark',
    NO: 'Norway',
    FI: 'Finland',
  }[legalUnit] || null);

export const convertServerDateTime = value => {
  return new Date(value).toLocaleString('nb-NO', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};
