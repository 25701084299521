import { Enumeration } from './Enumeration';

class PresentationModeEnum extends Enumeration {
  View = 'view';
  Edit = 'edit';
  Add = 'add';

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const PresentationMode = new PresentationModeEnum();
