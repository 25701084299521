import { Enumeration } from './Enumeration';

class NotificationTypeEnum extends Enumeration {
  Success = 'success';
  Info = 'info';
  Error = 'error';
  Warning = 'warning';
  Progress = 'progress';
  General = 'general';

  constructor() {
    super();
    return Object.freeze(this);
  }
}

export const NotificationType = new NotificationTypeEnum();
