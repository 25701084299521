import React from 'react';
import './Error.css';
import { formatMessage } from '../../../../Localization/formatMessage';
import Messages from '../../../../Localization/Messages';
import { useGlobalAddressContext } from '../../AddressProvider';
import { addressActions } from '../../AddressConstants';

const Error = () => {
  const {
    setAddressAction,
    isModalOpenedOnTeamLevel,
  } = useGlobalAddressContext();

  return (
    <>
      <p className="error-paragraph">
        {formatMessage(Messages.addressComponentError)}
      </p>
      <div style={{ textAlign: 'center' }}>
        {isModalOpenedOnTeamLevel ? (
          <button
            style={{ margin: 'auto' }}
            className="relax-button"
            onClick={() => {
              setAddressAction(addressActions.Create);
            }}>
            {formatMessage(Messages.addressComponentErrorCreate)}
          </button>
        ) : (
          <p className="error-paragraph">
            {formatMessage(Messages.addressComponentErrorDisclaimer)}
          </p>
        )}
      </div>
    </>
  );
};

export default Error;
